import { createReducer } from '@reduxjs/toolkit'
import { clearDateFilter, getCurrentMonthQuizData, getFilteredQuizData, setCurrentMonthQuizData, setDateFilter, setFilteredQuizData } from '../Actions/StudentLineGraphActions';
import { LineGraphResponseMapper } from '../../Services/DataMappers/LineGraphResponseMapper';

const currentDate = new Date();

export const initialState = {
    curretGraphData: [],
    currentMonth: currentDate.getMonth() + 1,
    currentYear: currentDate.getFullYear(),
    dateFilter: {
        startMonth: null,
        endMonth: null
    },
    isLoading: false
  }


export const StudentLineGraphReducer = createReducer(initialState, (builder) => {
    builder
    .addCase(getCurrentMonthQuizData, (state) => {
      state.isLoading = true;
    })
    .addCase(getFilteredQuizData, (state) => {
      state.isLoading = true;
    })
    .addCase(setCurrentMonthQuizData, (state, action) => {
      state.curretGraphData = action.payload ? LineGraphResponseMapper(action.payload) : []
      state.isLoading = false;
    })
    .addCase(setDateFilter, (state, action) => {
      state.dateFilter.startMonth = action.payload.startMonth
      state.dateFilter.endMonth = action.payload.endMonth
    })
    .addCase(setFilteredQuizData, (state, action) => {
        state.curretGraphData = action.payload ? LineGraphResponseMapper(action.payload) : []
        state.isLoading = false;
    })
    .addCase(clearDateFilter, (state) => {
      state.dateFilter.startMonth = null
      state.dateFilter.endMonth = null
    })
   
  })