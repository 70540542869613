import ClassroomFilter from './ClassroomFilter';

const ClassRoomHeader = () =>  {

  const schoolName = "Zahira College Colombo";

  return (
    <div className="class-room-header">
      <p className="header-school-name">{schoolName}</p>
      <ClassroomFilter />
    </div>
  );
}

export default ClassRoomHeader;