import { MappedStudent } from '../../Types/UpdatedStudentType';

export function filterAndMapByClassName(data: any[], className: string): MappedStudent[] {
  return data
    .filter(student => student.className === className)
    .map(student => ({
      label: student.name,
      value: student.email
    }));
}

// const filteredMappedList = filterAndMapByClassName(data, "8EF");
// console.log(filteredMappedList);