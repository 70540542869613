import { useState, useEffect } from 'react';
import {
  AuditOutlined,
  FormOutlined,
  HomeOutlined,
  LineChartOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RadarChartOutlined,
} from '@ant-design/icons'; 
import { Button, Menu } from 'antd';
import './styles.css'
import { useLocation, useNavigate } from 'react-router-dom'; // eslint-disable-line


const menuItems = [
    { name: 'Overview', path: '/' },
    { name: 'Strength Analysis', path: '/strength-analysis' },
    { name: 'Feedbacks', path: '/feedback' },
    { name: 'MAPT', path: '/feedback' },
    { name: 'SAPT', path: '/feedback' },
    { name: 'EAPT', path: '/feedback' },
    { name: 'Blackboard', path: '/draw' },
];


const items = [
  {
    key: '1',
    icon: <HomeOutlined />,
    label: 'Overview',
  },
  {
    key: '2',
    icon: <RadarChartOutlined />,
    label: 'Strength Analysis',
  },
  {
    key: '3',
    icon: <AuditOutlined />,
    label: 'Feedbacks',
  },
  {
    key: 'sub1',
    label: 'Progress Trackers',
    icon: <LineChartOutlined />,
    children: [
      {
        key: '4',
        label: 'MAPT',
      },
      {
        key: '5',
        label: 'SAPT',
      },
      {
        key: '6',
        label: 'EAPT',
      }
    ],
  },
  {
    key: '7',
    icon: <FormOutlined />,
    label: 'Blackboard',
  },
];


const SideNav = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(1);

    useEffect(() => {
        if (location) {
            const idx = menuItems.findIndex(x => x.path === location.pathname);
            setSelectedMenu(idx);
        }
    }, [location]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (e) => {
    console.log(e.key);
    handleNavigationOnClick(e.key)
  }

  
const handleNavigationOnClick = (key) => {
    console.log(key)
    navigate(menuItems[key - 1].path);
}

  return (
    <div className='mainSideNav'
    >
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        defaultSelectedKeys={selectedMenu}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={items}
        onClick={handleMenuClick}
      />
    </div>
  );
};
export default SideNav;