export const ConceptFeedback = {
    "data": {
        "strand": null,
        "unit": null,
        "feedbacks": [
            {
                "generalFeedback": null,
                "expertFeedback": "https://drive.google.com/file/d/1xSyVc_4wtPrJE_zurTyLmyENgC-yqR5_/view?usp=drive_link",
                "feedbackAuthor": "faiz",
                "feedbackDate": "2024-05-15 15:33:04.742813",
                "questionsAttempted": 15,
                "conceptScore": 14,
                "concept": "Using expression and formulae"
            },
            {
                "generalFeedback": null,
                "expertFeedback": "https://drive.google.com/file/d/1iLVj9KTMF4US3FfTMYKcqGC2Uy7FkxKQ/view?usp=drive_link",
                "feedbackAuthor": "faiz",
                "feedbackDate": "2024-05-15 15:33:04.742813",
                "questionsAttempted": 15,
                "conceptScore": 8,
                "concept": "Expanding brackets"
            },
            {
                "generalFeedback": null,
                "expertFeedback": "https://drive.google.com/file/d/17c73xOMpb5D5FWTkQeosbs8atb7SLfIR/view?usp=drive_link",
                "feedbackAuthor": "faiz",
                "feedbackDate": "2024-05-15 15:33:04.742813",
                "questionsAttempted": 15,
                "conceptScore": 5,
                "concept": "Constructing expressions"
            },
            {
                "generalFeedback": null,
                "expertFeedback": "https://drive.google.com/file/d/1Q3qJWzPXx7e5gS4vQr1REic6K0TnshBp/view?usp=drive_link",
                "feedbackAuthor": "faiz",
                "feedbackDate": "2024-05-15 15:33:04.742813",
                "questionsAttempted": 15,
                "conceptScore": 2,
                "concept": "Constructing and solving equations"
            },
            {
                "generalFeedback": null,
                "expertFeedback": "https://drive.google.com/file/d/1K68_Zt2ow4q3auv-3Wx26ciepdibh2fw/view?usp=drive_link",
                "feedbackAuthor": "faiz",
                "feedbackDate": "2024-05-15 15:33:04.742813",
                "questionsAttempted": 15,
                "conceptScore": 3,
                "concept": "Collecting like terms"
            },
        ]
    }
}