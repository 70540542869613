import keycloak from "./keycloak";
// /**
//  * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
//  *
//  * @param onAuthenticatedCallback
//  */
// const initKeycloak = (onAuthenticatedCallback) => {
//   keycloak.init({
//     onLoad: 'login-required'
//   })
//     .then((authenticated) => {
//       if (!authenticated) {
//         console.log("user is not authenticated..!");
//       }
//       onAuthenticatedCallback();
//     })
//     .catch(console.error);
// };

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
keycloak.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getUserEmail = () => keycloak.tokenParsed?.email;

const hasRole = (roles) => roles.some((role) => keycloak.hasRealmRole(role));

const UserService = {
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserEmail,
};

export default UserService;
