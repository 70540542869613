import {CheckSquareOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Card, Statistic } from 'antd';
import DateTime from '../Common/DateTime';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import { useEffect, useState } from 'react'; // eslint-disable-line
import UserService from '../../Services/UserService';
import { GET_STUDENT_PROGRESS } from '../../Redux/Actions/StudentProfileActions';

const initialProgress = {
  quizzesCompleted: 0,
  unitsCovered: 0
}

const QuizTracker = () => {

  const dispatch = useAppDispatch();
  const currentStudentProgress = useAppSelector((state) => state.studentProfileReducer.currentProgress);

  const [currentProgress, setProgress] = useState(initialProgress);

  useEffect(() => {
    dispatch({
      type: GET_STUDENT_PROGRESS,
      payload: {
        email: UserService.getUserEmail()
      }
    })
  }, [UserService.getUserEmail()])


  useEffect(() => {
    setProgress(currentStudentProgress)
  }, [currentStudentProgress])
      
  return(

      <Card bordered={false} className='dashboardQuizTracker'>
        <DateTime />
        <Statistic
            title="Quizzes completed"
            value={currentProgress.quizzesCompleted}
            valueStyle={{
              color: '#3f8600',
            }}
            prefix={<FileDoneOutlined />}
          />
        <Statistic
          title="Units Covered"
          value={currentProgress.unitsCovered}
          valueStyle={{
            color: '#cf1322',
          }}
          prefix={<CheckSquareOutlined />}
        />
      </Card>
  );
}
export default QuizTracker;