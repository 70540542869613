
import React, { useState }  from 'react';
import { useAppDispatch, useAppSelector } from "../../Redux/Store/hooks";
import dayjs from 'dayjs';
import { Button, Col, DatePicker, Descriptions, Row, message, Tooltip, Spin } from 'antd'; // eslint-disable-line

import './QuizResultFilterStyles.css'
import { CLEAR_DATE_FILTER, GET_CURRENT_MONTH_QUIZ_DATA, GET_FILTERED_QUIZ_DATA, SET_DATE_FILTER } from '../../Redux/Actions/StudentLineGraphActions';

const { RangePicker } = DatePicker;

const QuizResultFilter = () => {

    const dispatch = useAppDispatch();

    const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email);
    const currentMonth = useAppSelector((state) => state.studentLineGraphReducer.currentMonth); 
    const currentYear = useAppSelector((state) => state.studentLineGraphReducer.currentYear); 


    const [dateRange, setDateRange] = useState(null)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [dates, setDates] = useState(null); // eslint-disable-line

    const [searchHistoryDisabled, toggleSearchButton] = useState(false)
    const [clearHistoryDisabled, toggleClearButton] = useState(true)

    React.useEffect(() => {
        if(startDate !== "" && endDate !== ""){
          toggleSearchButton(true)
        }
        else{
          toggleSearchButton(false)
        }
      }, [dateRange])

    React.useEffect(() => {
        console.log(currentMonth)
        if(dateRange === null) {
            dispatch({
                type: GET_CURRENT_MONTH_QUIZ_DATA,
                payload: {
                    email: currentStudentEmail,
                    currentMonth: currentMonth,
                    currentYear: currentYear
                  }
              })
        }
    }, [dateRange])
    
    const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
    };

    const onDateChange = (value, dateString) => {
        if(value === null){
            toggleClearButton(true)
        }
        else{
            toggleClearButton(false)
        }
            setDateRange(value)
            setStartDate(dateString[0])
            setEndDate(dateString[1])
    };

    const handleSearchHistory = () => { 
        console.log("search clicked")
        dispatch({
            type: SET_DATE_FILTER,
            payload: {
                startDate: startDate,
                endDate: endDate
            }
        })
        dispatch({
          type: GET_FILTERED_QUIZ_DATA,
          payload: {
            email: currentStudentEmail,
            startDate: startDate,
            endDate: endDate
          }
        })
       }

    const clearHistory = () => {
        setDateRange(null)
        toggleClearButton(true)
        setStartDate("")
        setEndDate("")
        dispatch({
            type: CLEAR_DATE_FILTER
        })
       }
    

    return (
        <>
        <div className='filterOptions'>
            <Row className='filterOptionsRowTitle'><h3>Filter Results</h3></Row>
            <Row className='filterOptionsRow'>
                <RangePicker 
                onChange={onDateChange} 
                value={dateRange} 
                onCalendarChange={(val) => setDates(val)}
                disabledDate={disabledDate}
                picker="month"
            />
            </Row>
            <Row className='filterOptionsRow'><Button type="primary" disabled={!searchHistoryDisabled}  onClick={handleSearchHistory}>Show History</Button></Row>
            <Row className='filterOptionsRow'><Button disabled={clearHistoryDisabled} onClick={clearHistory}>Clear</Button></Row>
            </div>
        </>
      );
  };
  export default QuizResultFilter;