import { takeLatest, put, call } from "redux-saga/effects";  // eslint-disable-line
import { GET_FEEDBACKS_FOR_UNIT_STRAND, setFeedbacksForUnitAndStrand } from "../Actions/StudentFeedbackActions";
import { setErrorNotification } from "../Actions/notificationManagerActions";
import { getFeedbacksForConcepts } from "../../Services/API/StudentFeedbacksAPI";
import { ConceptFeedback } from "../../MockData/ConceptFeedbacks";



function* getStudentConceptFeedbacks(action){
    console.log(action)
    if((action.payload.email === "riz@gmail.com" || action.payload === "ahmad@pydium.com") && action.payload.unit === "Expressions, formulae and Equations"){
        yield put(setFeedbacksForUnitAndStrand(ConceptFeedback.data))
    }
    else {
        try {
            const response = yield call(getFeedbacksForConcepts, action.payload)
            if (response.status === 200) {
                yield put(setFeedbacksForUnitAndStrand(response.data.data))
            } else {
                yield put(setErrorNotification("Failed to fetch student profile"))
            }

        } catch (error) {
            console.log((error))
            yield put(setErrorNotification("Network Error"))
        }
    }
    // yield put(setFeedbacksForUnitAndStrand(ConceptFeedback.data))
}




  
export default function* watchStudentFeedbacksSaga(){
    yield takeLatest(GET_FEEDBACKS_FOR_UNIT_STRAND, getStudentConceptFeedbacks)
}