import { takeLatest, put, call } from "redux-saga/effects";  // eslint-disable-line
import { GET_STUDENT_PROFILE, GET_STUDENT_PROGRESS, setStudentProfile, setStudentProgress } from "../Actions/StudentProfileActions"; // eslint-disable-line
import { getStudentProfileCall, getStudentProgressCall } from "../../Services/API/StudentProfileAPI";
import { setErrorNotification } from "../Actions/notificationManagerActions";
// import { StudentProfile } from "../../MockData/TestStudentProfile";
// import { StudentProgressMetrics } from "../../MockData/StudentProgressMetrics";



function* getStudentProfileSaga(action){
    try {
        const response = yield call(getStudentProfileCall, action.payload.email)
        if(response.status === 200){
            yield put(setStudentProfile(response.data.data))
        }
        else{
            yield put(setErrorNotification("Failed to fetch student profile"))
        }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
    // yield put(setStudentProfile(StudentProfile.data))
}

function* getStudentProgressSaga(action) {
    try {
    const response = yield call(getStudentProgressCall, action.payload.email)
    if(response.status === 200){
        yield put(setStudentProgress(response.data.data))
    }
    else{
        yield put(setErrorNotification("Failed to fetch student progress"))
    }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
    // yield put(setStudentProgress(StudentProgressMetrics.data));
}



  
export default function* watchStudentProfileSaga(){
    yield takeLatest(GET_STUDENT_PROFILE, getStudentProfileSaga)
    yield takeLatest(GET_STUDENT_PROGRESS, getStudentProgressSaga)
}