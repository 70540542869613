import { all } from 'redux-saga/effects'
import watchStudentProfileSaga from './StudentProfileSaga'
import watchStudentLineGraphSaga from './StudentLineGraphSaga'
import watchStudentRadarGraphSaga from './StudentRadarGraphSaga'
import watchStudentFeedbacksSaga from './StudentFeedbackSaga'


export default function* rootSaga() {
    yield all([
      watchStudentProfileSaga(),
      watchStudentLineGraphSaga(),
      watchStudentRadarGraphSaga(),
      watchStudentFeedbacksSaga()
    ])
  }