import { createUrl, get, post } from "./axiosClient";

export async function getStudentSummary(email){
    try {
        const response = await 
            get(createUrl('responses/summary'), {
            params: {
               email: email
            }
           });
        return response;
     } catch (err) {
        console.log(err.message);
        return false
     }
  }; 


  export async function getConceptSummary(email){
   try {
       const response = await 
           get(createUrl('responses/summary/concept'), {
           params: {
              email: email
           }
          });
          console.log(response.data)
       return response;
    } catch (err) {
       console.log(err.message);
       return false
    }
 }; 


 export async function getConceptStrength(email){
   try {
       const response = await 
           get(createUrl('responses/summary/concept/strength'), {
           params: {
              email: email
           }
          });
          console.log(response.data)
       return response;
    } catch (err) {
       console.log(err.message);
       return false
    }
 }; 


 
// TODO: Add unit key
 export async function getFeedbacksSummary(object){
   try {
       const response = await 
           post(createUrl('/feedbacks/general'), {
              email: object.email,
              unit: object.unit
          });
          console.log(response.data)
       return response;
    } catch (err) {
       console.log(err.message);
       return false
    }
 }; 