import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import './ConceptRadarChartTemplateStyles.css'



ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const ConceptRadarChartTemplate = (props) => {

    const data = {
        labels: props.labels,
        datasets: [
          {
            label: 'Strength %',
            data: props.data,
            backgroundColor: 'rgba(107, 52, 235, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      };
  

      const options = {
        scale: {
            min: 0,
            max: 100,
        },
        // scales: {
        //     r: {
        //       ticks: {
        //         font: {
        //             size: 24
        //         }
        //       }
        //     }
        //   },
        scales: {
            r: {
              pointLabels: {
                color: 'rgb(77, 0, 115)',
                font: {
                    size: 14
                }
              }
            }
        },
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: 12
                    }
                }
            },
            tooltip: {
                bodyFont: {
                  size: 18,
                }
           }
        }
      }
      

return (
  <>
    <div className='chartContainer'>
        <Radar 
              data={data} 
              redraw={true} 
              updateMode={'active'}
              options={options}
            />
    </div>
  </>
          
);

};

export default ConceptRadarChartTemplate;