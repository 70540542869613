import axios from "axios";
import UserService from "../UserService";

const apiUrl = "https://app.pydium.com:8091/";
const jwtKey = "accessToken";
// let store

// export const injectStore = _store => {
//   store = _store
// }

axios.defaults.baseURL = apiUrl

axios.interceptors.request.use(
    (config) => {
      if (UserService.isLoggedIn()) {
        const cb = () => {
          config.headers.Authorization = `Bearer ${UserService.getToken()}`;
          return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
      }
      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  // axios.interceptors.response.use(
  //   (response) => { 
  //     return response;
  //   },
  //   (error) => { 
  //     if (error.request && error.request.status === 401) {
  //       alert("Session Expired!")
  //       store.dispatch(logout())
  //       return window.location.href = '/login'
  //     }
  //     if (error.request && error.request.status === 400) {
  //       return Promise.reject(error);
  //     }
  //     throw error;
  //   });

export const createUrl = (endpoint) => new URL(endpoint, apiUrl).href;
export const isStoredJwt = () => Boolean(localStorage.getItem(jwtKey));

export const setStoredJwt = (accessToken) =>
  localStorage.setItem(jwtKey, accessToken);

export const removeToken = () =>
  localStorage.removeItem(jwtKey);

export const get = axios.get;
export const patch = axios.patch;
export const post = axios.post;
export const put = axios.put;
export const deleteCall = axios.delete; 
