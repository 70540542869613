import { useKeycloak } from "@react-keycloak/web";
import UserService from "../../Services/UserService";
import CSVUploadModal from "../CSVUploaders/CSVHandler/CSVUploadModal";

import CSVActions from "../CSVUploaders/CSVButtons/CSVActions";

const AdminOnlyRoute = () => {

  const { keycloak } = useKeycloak();


 return (
   <div>
      {!!keycloak.authenticated && (
                  <>
                   <h1>Hello {UserService.getUsername()}</h1> 
                   This is an admin only route
                   <CSVUploadModal />
                   <div className="csvUploadActions">
                      <CSVActions />
                   </div>
                   </>
                 )}
      
   </div>
 );
};

export default AdminOnlyRoute;
