import { createUrl, post } from "./axiosClient";
 
// TODO: Add unit key
 export async function getFeedbacksForConcepts(object){
   try {
       const response = await 
           post(createUrl('/feedbacks/concept-feedback'), {
              email: object.email,
              unit: object.unit,
              strand: object.strand
          });
          console.log(response.data)
       return response;
    } catch (err) {
       console.log(err.message);
       return false
    }
 }; 