import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Image, Tooltip } from "antd"
import {
    LoginOutlined,
    FormOutlined
} from '@ant-design/icons';

import logo from './Pydium_Logo.png'
import './AppHeaderStyles.css'
import UserService from '../../Services/UserService'; 
import { useKeycloak } from '@react-keycloak/web'; // eslint-disable-line
import { useAppDispatch, useAppSelector } from "../../Redux/Store/hooks";
import { LOG_IN, LOG_OUT } from "../../Redux/Actions/StudentProfileActions"; // eslint-disable-line
import StudentProfileInfo from "../StudentProfileInfo/StudentProfileInfo";

export default function NewAppHeader() {
    const navigate = useNavigate();
    // const location = useLocation();
    const { keycloak } = useKeycloak();

    const dispatch = useAppDispatch(); // eslint-disable-line

    const isLoggedin = useAppSelector((state) => state.studentProfileReducer.isLoggedIn); // eslint-disable-line


    // React.useEffect(() => {
    // if(isLoggedIn){
    //     const origin = location.pathname || '/';
    //     console.log(origin)
    //     if(origin === loginPath){
    //         redirectToCabinets()
    //     }
    //     else{
    //         navigate(origin);
    //     }
    // }
    // else{
    //     navigate('/login')
    // }
    // }, [isLoggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

    const redirectToHome = () => { 
        navigate('/')
    }

    // const redirectToAdminOnlyRoute = () => {
    //     navigate('/admin-only-route')
    // }

    const navigateToDraw = () => {
        navigate('/draw')
    }

    // const logoutUser = () => {
    //     UserService.doLogout()
    //     // dispatch({
    //     //     type: LOG_OUT
    //     // })

    // }

    const loginUser = () => {
        UserService.doLogin()
        // dispatch({
        //     type: LOG_IN
        // })
    }

    return (
        !!keycloak.authenticated ? // eslint-disable-line
            <Row className="headerContainer">
                <Col span={12}>
                    <Row className="headerTitle"
                        wrap={false}
                        type="flex"
                        justify="center"
                    >
                        <Image src={logo} preview={false} className="logo" onClick={redirectToHome} />
                        <div style={{ paddingLeft: "30px" }}>
                            <p>Maths Assessment & Progress Tracker</p>
                        </div>
                    </Row>
                </Col>
                <Col span={12} style={{ textAlign: "right", height: "inherit", paddingRight: "30px" }}>
                    <Space size={24}>
                       <StudentProfileInfo />
                    </Space>
                </Col>
            </Row>

            :
            <Row className="headerContainer">
                <Col span={12}>
                    <Row className="headerTitle"
                        wrap={false}
                        type="flex"
                        justify="center"
                    >
                        <Image src={logo} preview={false} className="logo" onClick={redirectToHome} />
                        <div style={{ paddingLeft: "30px"}}>
                            <p>Maths Assessment & Progress Tracker</p>
                        </div>

                    </Row>
                </Col>
                <Col span={12} style={{ textAlign: "right", height: "inherit", paddingRight: "30px" }}>
                    <Space size={24}>
                        <Tooltip placement="bottom" title={"Draw"}>
                            <FormOutlined onClick={navigateToDraw} />
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Login"}>
                            <LoginOutlined onClick={loginUser} />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>

    )
}