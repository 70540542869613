import { Button } from 'antd';
import './FooterStyles.css'
import { useKeycloak } from '@react-keycloak/web';
import UserService from '../../Services/UserService';

const footerNavItems = [
    { name: 'FAQ', path: '/faq' },
    { name: 'About us', path: '/about-us' },
    { name: 'Profile', path: '/profile' }
];



const CustomFooter = (props) => {

    const userLogout = () =>  {
        UserService.doLogout();
    }

    const {keycloak} = useKeycloak();
    return (
    <>
        <div className="mainFooter">
            <div className='footerNavContainer'>
                {footerNavItems.map((item, index) => {
                    return <div className='footerNavItem' key={index}><Button>{item.name}</Button></div>
                })}
                {!!keycloak.authenticated && 
                <div  className='footerNavItem'><Button className="signOutBtn" onClick={userLogout}>Sign out</Button></div>}
            </div>
        </div>
    </>
    )
};


export default CustomFooter;