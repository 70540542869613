import { createAction } from "@reduxjs/toolkit"

export const GET_ACTIVITY_REPORT = 'GET_ACTIVITY_REPORT'
export const UPDATE_ACTIVITY_TABLE = 'UPDATE_ACTIVITY_TABLE'
export const TOGGLE_TRANSACTIONS_VIEW_MODAL = 'TOGGLE_TRANSACTIONS_VIEW_MODAL'
export const CLOSE_TRANSACTIONS_MODAL_VIEW = 'CLOSE_TRANSACTIONS_MODAL_VIEW'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS'
export const CLEAR_ACTIVITY_TABLE = 'CLEAR_ACTIVITY_TABLE'
export const SET_CURRENT_WORKER_LOGS = 'SET_CURRENT_WORKER_LOGS'
export const TOGGLE_LIST_LOADING = 'TOGGLE_LIST_LOADING'
export const FILTER_ACTIVITY_LOGS = 'FILTER_ACTIVITY_LOGS'

export const getActivityReport = createAction(GET_ACTIVITY_REPORT)
export const toggleTransactionsViewModal = createAction(TOGGLE_TRANSACTIONS_VIEW_MODAL)
export const closeTransactionsViewModal = createAction(CLOSE_TRANSACTIONS_MODAL_VIEW)
export const getTransactions = createAction(GET_TRANSACTIONS)
export const setTransactions = createAction(SET_TRANSACTIONS)
export const clearActivityTable = createAction(CLEAR_ACTIVITY_TABLE)
export const setCurrentWorkerLogs = createAction(SET_CURRENT_WORKER_LOGS)
export const toggleListLoading = createAction(TOGGLE_LIST_LOADING)
export const updateActivityTable = createAction(UPDATE_ACTIVITY_TABLE)
export const filterActivityLogs = createAction(FILTER_ACTIVITY_LOGS)