import React, { useEffect, useState } from "react"; // eslint-disable-line
import UserService from "../../Services/UserService";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { GET_STUDENT_PROFILE } from "../../Redux/Actions/StudentProfileActions";
import { Col, Row, Skeleton } from 'antd';
import { UserOutlined, MailOutlined, BankOutlined } from '@ant-design/icons'; // eslint-disable-line

import './StudentProfileInfoStyles.css'

const initialStudentData = {
  name: "",
  school: "",
  year: "", 
  email: "", 
  guardian: ""
}

const StudentProfileInfo = () => {

    const dispatch = useAppDispatch();

    const { keycloak } = useKeycloak(); // eslint-disable-line
    
    const isLoading = useAppSelector((state) => state.studentProfileReducer.isLoading); // eslint-disable-line
    const currentStudentProfile = useAppSelector((state) => state.studentProfileReducer.currentStudent); // eslint-disable-line

    const [currentStudent, setCurrentStudent] = useState(initialStudentData);

    useEffect(() => {
      
        dispatch({
          type: GET_STUDENT_PROFILE,
          payload: {
            email: UserService.getUserEmail()
          }
        })
      }, [])

    useEffect(() => {
      console.log(currentStudentProfile)
      setCurrentStudent(currentStudentProfile)
    }, [currentStudentProfile])

   return (
     <div>
        {!!keycloak.authenticated && (
                    <>
                    {isLoading? <Skeleton active /> :
                      <Row style={{display: 'flex', justifyContent:'center'}}> 
                      <Col>
                        <Row className="userInfoIconRow">
                          <UserOutlined/> <div className="userName"> {currentStudent.name}</div>
                        </Row>
                        
                      {/* </Col>
                      <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Row className="userInfoIconRow">
                          <MailOutlined />   <h1> {currentStudent.email}</h1>
                        </Row>
                      </Col>
                      <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Row className="userInfoIconRow">
                          <BankOutlined />  <h1> {currentStudent.school}</h1>
                        </Row> */}
                      </Col>
                    </Row>
                    }
                     </>
                   )}
        
     </div>
   );
  };
  
  export default StudentProfileInfo;
  