import { takeLatest, put, call } from "redux-saga/effects";
import { GET_CURRENT_MONTH_QUIZ_DATA, GET_FILTERED_QUIZ_DATA, setCurrentMonthQuizData, setFilteredQuizData } from "../Actions/StudentLineGraphActions";
import { setErrorNotification } from "../Actions/notificationManagerActions";
import { getCurrentMonthQuizData, getFilteredMonthQuizData } from "../../Services/API/StudentLineGraphAPI";

function* getCurrentMonthQuizDataSaga(action){
    try {
        console.log(action.payload)
        const response = yield call(getCurrentMonthQuizData, action.payload)

        if(response.status === 200){
            yield put(setCurrentMonthQuizData(response.data.data))
        }
        else{
            yield put(setErrorNotification("Failed to fetch student quiz data"))
        }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
}

function* getFilteredQuizDataSaga(action){
    try {
        console.log(action.payload)
        const response = yield call(getFilteredMonthQuizData, action.payload)
        if(response.status === 200){
            yield put(setFilteredQuizData(response.data.data))
        }
        else{
            yield put(setErrorNotification("Failed to fetch student quiz data"))
        }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
}


  
export default function* watchStudentLineGraphSaga(){
    yield takeLatest(GET_CURRENT_MONTH_QUIZ_DATA, getCurrentMonthQuizDataSaga)
    yield takeLatest(GET_FILTERED_QUIZ_DATA, getFilteredQuizDataSaga)
}