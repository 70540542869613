import React, { useState } from 'react';
import { Button, Modal, Carousel } from 'antd';

import './DashboardNotificationStyles.css'

const DashboardNotification = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState('');

  const notifications = [
    {
      id: 1,
      message: 'New feedback available',
    },
    {
      id: 2,
      message: 'New Quizzes added',
    },
    {
      id: 3,
      message: 'New Resources added',
    },
  ];

  const showModal = (content) => {
    setNotificationContent(content);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Carousel autoplay dots={false} speed={1000} autoplaySpeed={4000} className='notificationCorousel'>
        {notifications.map((notification) => (
          <div key={notification.id}>
            <div>{notification.message}</div>
            <Button type="primary" onClick={() => showModal(notification.message)}>
              Open
            </Button>
          </div>
        ))}
      </Carousel>
      <Modal title="Notification" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText={"Close"}>
        <p>{notificationContent}</p>
      </Modal>
    </>
  );
};

export default DashboardNotification;
