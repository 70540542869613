import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/hooks';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload/interface';
import CSVFileViewTable from './StudentCSVFileViewTable';
import { Button, Modal, Row, Col, message, Upload   } from 'antd';
import './CSVViewStyles.css'
import { CSV_UPLOAD, SET_CSV_MODAL_CLOSED } from '../../../Redux/Actions/StudentProfileActions';




const CSVUploadModal: React.FC = (): JSX.Element => {

  const dispatch = useAppDispatch();

  const modalStatus = useAppSelector((state) => state.studentProfileReducer.isCSVModalOpen)
  const uploadingCSV = useAppSelector((state) => state.studentProfileReducer.uploadingCSV)

  const [file, setFile] = useState<Object>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileArray, setArray] = useState([]);
  const [fileName, setFilename] = useState("");

  

  React.useEffect(() => {
    if(modalStatus) {
      setIsModalOpen(true)
    }
    else{
      setIsModalOpen(false)
      setFilename("")
      setArray([])
    }
    
  }, [modalStatus])

  const handleUpload = () => {  // eslint-disable-line
    dispatch({
      type: CSV_UPLOAD,
      payload: file
    })
  };

  const csvFileToArray = (string: any) => { // eslint-disable-line
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    console.log(csvHeader)
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n"); // eslint-disable-line
    const array = csvRows.map((i: any) => {
     
      const values = i.split(",");
      const obj = csvHeader.reduce((object: { [x: string]: any; }, header: string | number, index: string | number) => {
        object[header] = values[index];
        
        return object;
      }, {});
      return obj;
    });
    setArray(array);
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      console.log(file.type)
      const isCsv = 
      file.type === 'application/vnd.ms-excel' || 
      file.type === 'text/csv';
      if(!isCsv){
        message.error("Uploaded file is not a csv") // eslint-disable-line
      }
      else{
      const reader = new FileReader();
        reader.onload = (e:any) => {
          const text = e.target.result;
          if (e.target.files) {   // eslint-disable-line
            setFile(e.target.files[0]);
          }
          const parsedText = JSON.parse(JSON.stringify(text)).replaceAll('"', "").replaceAll(/\r/g, '')
          csvFileToArray(parsedText);
        };
        
        reader.readAsText(file);
        setFilename(file.name)
      }
        return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {  
        message.success(`${info.file.name} file uploaded successfully`); // eslint-disable-line
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);  // eslint-disable-line
      }
      
      setFile(info.file)
    },
    showUploadList: false,
    multiple: false,
    maxCount: 1,
    accept: ".csv"
  };

  const onClose = () => {  // eslint-disable-line
    dispatch({
      type: SET_CSV_MODAL_CLOSED
    })
  };

  const headerKeys = Object.keys(Object.assign({}, ...fileArray));

  return (
    <>
    <Modal
        title="Import CSV"
        centered
        open={isModalOpen}
       // open={openOrderCartModal}
      // onOk={confirmOrder}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        style={
            {
              minWidth: "40vw",
              maxWidth: "50vw"
            }
          }
      >
        <Row className="csvViewHeader">
          <Col span={8}>
          <Upload {...props}>
            <Button size="large" icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          </Col>
          <Col span={8}>
           <p>{fileName}</p> 
          </Col>
          <Col span={8}>
          <Button
            size="large"
            type="primary"
            onClick={handleUpload}
            disabled={fileName === ""}
            loading={uploadingCSV}
          >
          {uploadingCSV ? 'Uploading' : 'Start Upload'}
      </Button>
          </Col>
        </Row>
        <Row>
        <CSVFileViewTable tableData={[fileArray]} headerKeys={headerKeys}/>
        </Row>
    </Modal>
    </>
  );
};

export default CSVUploadModal;