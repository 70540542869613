import { createAction } from "@reduxjs/toolkit"

export const GET_CURRENT_SUMMARY = 'GET_CURRENT_SUMMARY'
export const GET_CONCEPT_SUMMARY = 'GET_CONCEPT_SUMMARY'
export const GET_CONCEPT_SUMMARY_FOR_STRENGTH = 'GET_CONCEPT_SUMMARY_FOR_STRENGTH'
export const SET_CONCEPT_SUMMARY_FOR_STRENGTH = 'SET_CONCEPT_SUMMARY_FOR_STRENGTH'
export const SET_CURRENT_SUMMARY = 'SET_CURRENT_SUMMARY'
export const SET_CONCEPT_SUMMARY = 'SET_CONCEPT_SUMMARY'
export const SET_CURRENT_FEEDBACK = 'SET_CURRENT_FEEDBACK'
export const GET_CURRENT_FEEDBACK = 'GET_CURRENT_FEEDBACK'
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK'


export const getCurrentSummary = createAction(GET_CURRENT_SUMMARY)
export const setCurrentSummary = createAction(SET_CURRENT_SUMMARY)
export const getConceptSummary = createAction(GET_CONCEPT_SUMMARY)
export const getConceptSummaryForStrength = createAction(GET_CONCEPT_SUMMARY_FOR_STRENGTH)
export const setConceptSummaryForStrength = createAction(SET_CONCEPT_SUMMARY_FOR_STRENGTH)
export const setConceptSummary = createAction(SET_CONCEPT_SUMMARY)
export const setCurrentFeedback = createAction(SET_CURRENT_FEEDBACK)
export const getCurrentFeedback = createAction(GET_CURRENT_FEEDBACK)
export const clearFeedback = createAction(CLEAR_FEEDBACK)