import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import { GET_CONCEPT_SUMMARY } from "../../Redux/Actions/StudentRadarChartActions";
import { Table, Space, Select } from 'antd';
import ConceptRadarChartTemplateApex from '../ConceptRadarCharts/ConceptRadarChartTemplateApex';
import { GET_FEEDBACKS_FOR_UNIT_STRAND } from '../../Redux/Actions/StudentFeedbackActions';
import { ConceptFeedbacksMapper } from '../../Services/DataMappers/ConceptFeedbacksMapper';
import './styles.css'

const columns = [
    {
        title: 'Concept',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Questions Attempted',
        dataIndex: 'questionsAttempted',
        key: 'questionsAttempted',
    },
    {
        title: 'Concept Score',
        dataIndex: 'conceptScore',
        key: 'conceptScore',
    },
    {
        title: 'Feedback',
        key: 'action',
        render: (_, rec) => (
            <Space size="middle">
                <a href={rec.action} rel="noopener noreferrer" target="_blank">Get Feedback</a>
            </Space>
        ),
    },
];

export default function Feedback() {

    const dispatch = useAppDispatch();

    const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email);

    const conceptRadarData = useAppSelector((state) => state.studentRadarGraphReducer.conceptSummary);

    const unitFeedbacks = useAppSelector((state) => state.studentFeedbacksReducer.feedbacks); // eslint-disable-line

    const [strands, setStrands] = useState([]); // eslint-disable-line
    const [selectedStrand, setSelectedStrand] = useState(); // eslint-disable-line
    const [units, setUnits] = useState([]);// eslint-disable-line
    const [selectedUnit, setSelectedUnit] = useState();// eslint-disable-line
    const [feedbacks, setFeedbacks] = useState([])



    useEffect(() => {
        dispatch({
            type: GET_CONCEPT_SUMMARY,
            payload: currentStudentEmail
        });
    }, [currentStudentEmail]);

    
    useEffect(() => {
        if(currentStudentEmail && selectedUnit && selectedStrand) {
        dispatch({
            type: GET_FEEDBACKS_FOR_UNIT_STRAND,
            payload: {
              email: currentStudentEmail,
              unit: selectedUnit,
              strand: selectedStrand
            }
        })
    }
    }, [currentStudentEmail, selectedUnit, selectedStrand]);

 

    useEffect(() => {
       
        if (conceptRadarData != null) {
            const strands = Object.keys(conceptRadarData)
            const units = Object.keys(conceptRadarData[strands[0]]);
            setStrands(strands);
            setSelectedStrand(strands[0]);
            setUnits(units);
            setSelectedUnit(units[0]);
        
        }
    }, [conceptRadarData]);

    useEffect(() => {
        if(unitFeedbacks != null){
            setFeedbacks(unitFeedbacks)
        }
    }, [unitFeedbacks]);
    
    const extractValuesFromCategory = (unit, category) => {
        const correctValues = [];
        const subCategories = Object.keys(conceptRadarData[unit][category]);
        subCategories.forEach((subCategory) => {
            if(conceptRadarData[unit][category][subCategory].value === undefined || isNaN(conceptRadarData[unit][category][subCategory].value)) {
              correctValues.push(0);
            }
            else {
              correctValues.push(conceptRadarData[unit][category][subCategory].value);
            }
        })
        console.log(correctValues);
        return correctValues;
      }

      const handleConceptsAndUnitsChange = (value, type) => {
        if (type === 'strand') {
            setSelectedStrand(value);
            const units = Object.keys(conceptRadarData[value]);
            setUnits(units);
            setSelectedUnit(units ? units[0] : undefined);
            return;
        }

        if (type === 'unit') {
            setSelectedUnit(value);
        }
    }
    return (
        <>
         <div style={{
                display: 'flex',
                justifyContent: 'end',
                columnGap: '2em'
            }}>
                <div className='filter-comp'>
                    <label>Strand</label>
                    <Select
                        id='swat-unit'
                        size={'large'}
                        value={selectedStrand}
                        onChange={(v) => handleConceptsAndUnitsChange(v, 'strand')}
                        style={{
                            width: 200
                        }}
                        options={strands.map((item) => {
                            return {
                                label: item,
                                value: item
                            }
                        })}
                    />
                </div>
                <div className='filter-comp'>
                    <label>Unit</label>
                    <Select
                        id='swat-concept'
                        size={'large'}
                        value={selectedUnit}
                        onChange={(v) => handleConceptsAndUnitsChange(v, 'unit')}
                        style={{
                            width: 200
                        }}
                        options={units.map((item) => {
                            return {
                                label: item,
                                value: item
                            }
                        })}
                    />
                </div>
            </div>
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    marginTop: '1em',
                    columnGap: '2em',
                }}>
  
            <div className='radarchartContainerCS'>
                <div >Concept Breakdown</div>
                    {conceptRadarData && selectedStrand && selectedUnit && <ConceptRadarChartTemplateApex
                            labels={Object.keys(conceptRadarData[selectedStrand][selectedUnit])}
                            data={extractValuesFromCategory(selectedStrand, selectedUnit)}
                        /> }
                    </div>

            {unitFeedbacks && selectedStrand && selectedUnit && feedbacks &&
                <div className='feedbackTable'>
                    <Table locale={{ emptyText: "No feedbacks received for this unit yet" }} columns={columns} dataSource={ConceptFeedbacksMapper(feedbacks)} />
                </div> 
            }           
        </div>
            
        </>
    );
}