import QuizResultFilter from "../QuizResultFilter/QuizResultFilter";
import StudentLineGraph from "../StudentLineGraph/StudentLineGraph";


const StudentMonthlyOverview = () => {

return (
    <>
        <QuizResultFilter />
        <div>
        <h2 className="graphTitle">Math Quiz Overview</h2>
        </div>

        <div className="mainGraph">
            <StudentLineGraph />
        </div>
    </>
    );
};

export default StudentMonthlyOverview;
