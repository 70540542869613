import { student } from "../../Types/StudentType"

export const StudentResponseMapper = (res: any): any => {
    const studentData: student = {
        name: res.name,
        year: res.year,
        school: res.school? res.school : "NA", // eslint-disable-line
        email: res.email,
        guardian: res.guardian
    }
    return studentData;
}