import { useAppDispatch, useAppSelector } from '../../../Redux/Store/hooks';
import React, { useState } from 'react';
import { Collapse, Skeleton, Tabs } from 'antd'; // eslint-disable-line
import { GET_CONCEPT_SUMMARY, GET_CURRENT_FEEDBACK } from "../../../Redux/Actions/StudentRadarChartActions";
import ConceptRadarChartTemplate from '../../ConceptRadarCharts/ConceptRadarChartTemplate'; // eslint-disable-line
import './ConceptWiseRadarChartStyles.css'
import ConceptRadarChartTemplateApex from '../../ConceptRadarCharts/ConceptRadarChartTemplateApex';


const ConceptWiseRadarChartTabs = () => {

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.studentRadarGraphReducer.isLoading);

  const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email);

  const conceptRadarData = useAppSelector((state) => state.studentRadarGraphReducer.conceptSummary);

  const [radarData, setRadarData] = useState(null) // eslint-disable-line
  const [radarTabs, setRadarTabs] = useState([]) // eslint-disable-line
  const [conceptTabs, setConceptTabs] = useState([]) // eslint-disable-line
  const [conceptTabsAcc, setConceptTabsAcc] = useState([]) // eslint-disable-line

 
  React.useEffect(() => {
    dispatch({
        type: GET_CONCEPT_SUMMARY,
        payload: currentStudentEmail
      })
  }, [])

  React.useEffect(() => {
    if(conceptRadarData != null) {
      console.log(Object.keys(conceptRadarData))
      setRadarData(conceptRadarData)
      setRadarTabs(Object.keys(conceptRadarData))
    }
  }, [conceptRadarData])

  React.useEffect(() => {
    if(radarTabs.length !== 0) {
      const items = []
      const accordianItems = []
      radarTabs.forEach((unit) => {
        const strands = Object.keys(conceptRadarData[unit])
        const subTabs = [];
        strands.forEach((category) => {
          const tab = {            
            key: category,
            label: category,
            children: 
              <>
              <div className='conceptRadarChartContainer'>
                <ConceptRadarChartTemplateApex labels={Object.keys(conceptRadarData[unit][category])} data={extractValuesFromCategory(category, unit)} />
              </div>
              </>
          }
          subTabs.push(tab)
        })
        const mainTab = {
          key: unit,
          label: unit,
          children: 
            <Tabs
              tabPosition='left'
              className='conceptRadarTabContainer'
              defaultActiveKey="1"
              centered
              items={subTabs}
              onChange={onChange}
            />
        
        }

        const accMainTab = {
          key: unit,
          label: unit,
          children:
          <Collapse accordion className='conceptRadarTabAccordion' 
          items={subTabs} 
          bordered={false} 
          defaultActiveKey={['1']}
          onChange={onChange}
          />

        }
        items.push(mainTab)
        accordianItems.push(accMainTab)
      })
      
      setConceptTabs(items)
      setConceptTabsAcc(accordianItems)
    }
  }, [radarTabs])


  const applyWordbreakForLongTexts = (labels) => { // eslint-disable-line

    const newLabels = [];
    labels.forEach((label) => {
        if (label.length > 10) {
          const wordsArray = label.split(' ');
    
          newLabels.push(wordsArray);
        }
        else {
          newLabels.push(label)
        }
    })
    console.log(newLabels);
    return newLabels;
  }
  

  const extractValuesFromCategory = (category, unit) => {
    const correctValues = [];
    const subCategories = Object.keys(conceptRadarData[unit][category]);
    subCategories.forEach((subCategory) => {
        if(conceptRadarData[unit][category][subCategory].value === undefined || isNaN(conceptRadarData[unit][category][subCategory].value)) {
          correctValues.push(0);
        }
        else {
          correctValues.push(conceptRadarData[unit][category][subCategory].value);
        }
    })
    console.log(correctValues);
    return correctValues;
  }


  const onChange = (key) => {
      console.log(key);
      dispatch({
        type: GET_CURRENT_FEEDBACK,
        payload: {
          email: currentStudentEmail,
          unit: Array.isArray(key) ? key[0] : key
        }
      })
  };
    
return (
  <>
  {isLoading ? < Skeleton active /> :
  <>
    <Tabs
    tabPosition='left'
    className='conceptRadarTabContainer'
    defaultActiveKey="1"
    centered
    items={conceptTabs}
    onChange={onChange}
  />
  <Collapse accordion className='conceptRadarTabAccordion' items={conceptTabsAcc} bordered={false} defaultActiveKey={['1']} onChange={onChange}/>
  </>
  }
  </>
 
);

};
export default ConceptWiseRadarChartTabs;
