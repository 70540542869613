import { createReducer } from '@reduxjs/toolkit'
import { closeTransactionsViewModal
} from '../Actions/activityReportActions'

export const initialState = {
    logs: [],
    currentWorkerLogs: {
      key: "",
      date: "", 
      timeIn: "", 
      timeOut: "", 
      nfcID: "", 
      employeeID: "", 
      name: "",
      cabinetID: ""
    },
    isModalOpen: false,
    selectedUserTransactions: [],
    loadingList : false,
    loadingTransactions: false,
  }
  
const activityReportReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(closeTransactionsViewModal, (state) => {
    state.isModalOpen = false
    state.currentWorkerLogs = []
    state.selectedUserTransactions = []
  })
 
})

  export default activityReportReducer;