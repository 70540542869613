export const shezanConceptBreakdown = {
    "data": {
        "totalQuestions": 443,
        "strandUnitConceptBreakdown": {
            "Algebra": {
                "Sequences and functions": {
                    "Generating sequences (2)": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    },
                    "Generating sequences (1)": {
                        "numberOfCorrect": 8,
                        "numberOfIncorrect": 6
                    },
                    "Representing simple functions": {
                        "numberOfCorrect": 10,
                        "numberOfIncorrect": 5
                    },
                    "Using the nth term": {
                        "numberOfCorrect": 5,
                        "numberOfIncorrect": 10
                    }
                },
                "Expressions, formulae and Equations": {
                    "Constructing Expressions": {
                        "numberOfCorrect": 5,
                        "numberOfIncorrect": 10
                    },
                    "Using expressions and formulae": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Collecting like terms": {
                        "numberOfCorrect": 3,
                        "numberOfIncorrect": 12
                    },
                    "Expanding brackets": {
                        "numberOfCorrect": 8,
                        "numberOfIncorrect": 7
                    },
                    "Constructing and solving equations": {
                        "numberOfCorrect": 2,
                        "numberOfIncorrect": 13
                    }
                },
                "Graphs": {
                    "Graphs": {
                        "Graphs of functions": {
                            "numberOfCorrect": 8,
                            "numberOfIncorrect": 7
                        },
                        "Interpreting graphs":{
                            "numberOfCorrect": 8,
                            "numberOfIncorrect": 7
                        },
                        "Lines parallel to the axes":{
                            "numberOfCorrect": 8,
                            "numberOfIncorrect": 7
                        },
                        "Functions":{
                            "numberOfCorrect": 8,
                            "numberOfIncorrect": 7
                        }
                    }
                }
            },
            "Geometry": {
                "Angles": {
                    "Adjacent angles on a straight line": {
                        "numberOfCorrect": 7,
                        "numberOfIncorrect": 2
                    },
                    "Complementary & Supplementary angles": {
                        "numberOfCorrect": 5,
                        "numberOfIncorrect": 2
                    },
                    "Vertically opposite angles": {
                        "numberOfCorrect": 7,
                        "numberOfIncorrect": 0
                    },
                    "Angles around a point": {
                        "numberOfCorrect": 2,
                        "numberOfIncorrect": 2
                    }
                }
            },
            "Number": {
                "Integers": {
                    "Multiplying and dividing integers": {
                        "numberOfCorrect": 12,
                        "numberOfIncorrect": 3
                    },
                    "Highest common factors": {
                        "numberOfCorrect": 10,
                        "numberOfIncorrect": 5
                    },
                    "Adding and Subtracting integers": {
                        "numberOfCorrect": 11,
                        "numberOfIncorrect": 4
                    },
                    "Tests for divisibility": {
                        "numberOfCorrect": 6,
                        "numberOfIncorrect": 9
                    },
                    "Lowest common multiples": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    }
                },
                "Ratio and Proportion": {
                    "Sharing in a ratio": {
                        "numberOfCorrect": 6,
                        "numberOfIncorrect": 9
                    },
                    "Simplifying ratios": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Using direct proportions": {
                        "numberOfCorrect": 6,
                        "numberOfIncorrect": 9
                    }
                },
                "Percentages": {
                    "Fractions decimals and percentages": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Percentages large and small": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    }
                },
                "Fractions": {
                    "Making fraction calculations easier": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Adding mixed numbers": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Multiplying fractions": {
                        "numberOfCorrect": 6,
                        "numberOfIncorrect": 9
                    },
                    "Ordering fractions": {
                        "numberOfCorrect": 6,
                        "numberOfIncorrect": 9
                    },
                    "Dividing fractions": {
                        "numberOfCorrect": 6,
                        "numberOfIncorrect": 9
                    }
                },
                "Decimals": {
                    "Dividing decimals": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Multiplying decimals": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    },
                    "Making decimal calculations easier": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Adding and subtracting decimals": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    },
                    "Ordering decimals": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    }
                }
            },
            "Probability": {
                "Probability": {
                    "Mutually exclusive outcomes": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    },
                    "Experimental probabilities": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    },
                    "The probability scale": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    }
                }
            },
            "Statistics": {
                "Collecting data": {
                    "Conducting an investigation": {
                        "numberOfCorrect": 14,
                        "numberOfIncorrect": 1
                    },
                    "Taking a sample": {
                        "numberOfCorrect": 9,
                        "numberOfIncorrect": 6
                    }
                }
            }
        }
    }
}