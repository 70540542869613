import { useAppSelector, useAppDispatch } from "../../Redux/Store/hooks";
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { GET_CURRENT_SUMMARY } from "../../Redux/Actions/StudentRadarChartActions";
import { Skeleton } from "antd";
import './StudentRadarChartStyles.css'
import ConceptFilter from "../ConceptFilter/ConceptFilter";
import ConceptRadarChartTemplateApex from "../ConceptRadarCharts/ConceptRadarChartTemplateApex";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const StudentConceptRadarChart = () => {

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.studentRadarGraphReducer.isLoading);
  
  const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email);

  const levelWiseData = useAppSelector((state) => state.studentRadarGraphReducer.levelWiseStrength); // eslint-disable-line
  const typeWiseData = useAppSelector((state) => state.studentRadarGraphReducer.typeWiseStrength); // eslint-disable-line

  const [levelValues, setLevelValues] = useState([]) // eslint-disable-line
  const [typeValues, setTypeValues] = useState([]) // eslint-disable-line

  React.useEffect(() => {
        dispatch({
            type: GET_CURRENT_SUMMARY,
            payload: currentStudentEmail
          })
}, [])


  React.useEffect(() => {
    console.log(levelWiseData.levelScores)
    console.log(typeWiseData.typeScores)
    setLevelValues(levelWiseData.levelScores)
    setTypeValues(typeWiseData.typeScores)
  }, [levelWiseData, typeWiseData])
  

return (
    <>
    {isLoading? <Skeleton active /> :
    <>
    <div className="conceptFilter">
      <ConceptFilter />
    </div>
    <div className="competencyChartContainer">
      
      {/* <ConceptRadarChartTemplate labels={['Theory', 'Illustration', 'Worded']} data={typeWiseData.typeScores} />
      <ConceptRadarChartTemplate labels={['Easy', 'Medium', 'Hard']} data={levelWiseData.levelScores} /> */}
      <ConceptRadarChartTemplateApex labels={['Theory', 'Composite', 'Worded']} data={typeWiseData.typeScores}/>
      <ConceptRadarChartTemplateApex labels={['Easy', 'Medium', 'Hard']} data={levelWiseData.levelScores}/>

    </div>
    </>
    } 
    </>
);

};

export default StudentConceptRadarChart;