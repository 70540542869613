import { Skeleton, Tabs } from 'antd';
import StudentMonthlyOverview from '../StudentMonthlyOverview/StudentMontlyOverview';
import StudentConceptRadarChart from '../StudentRadarCharts/StudentConceptRadarChart';
import { useAppSelector } from '../../Redux/Store/hooks';
import ReportLayout from '../Training & Feedback/ReportLayout';
const StudentDashboardTabs = () => {

  const isLoading = useAppSelector((state) => state.studentProfileReducer.isLoading); // eslint-disable-line


    const onChange = (key) => {
        console.log(key);
      };
      const items = [
        {
          key: '1',
          label: 'Monthly Overview',
          children: <StudentMonthlyOverview />,
        },
        {
          key: '2',
          label: 'Strength Analysis',
          children: <StudentConceptRadarChart />,
        },
        {
          key: '3',
          label: 'Training and Feedback',
          children: <ReportLayout />,
        },
      ];

return (
  <>
    {isLoading? <Skeleton active/> : 
    <Tabs
      defaultActiveKey="1"
      centered
      items={items}
      onChange={onChange}
    />
  }
  </>
  
);

};
export default StudentDashboardTabs;
