import { createAction } from "@reduxjs/toolkit"

export const GET_STUDENT_PROFILE = 'GET_STUDENT_PROFILE'
export const SET_STUDENT_PROFILE = 'SET_STUDENT_PROFILE'
export const CSV_UPLOAD = 'CSV_UPLOAD'
export const TOGGLE_CSV_MODAL = 'TOGGLE_CSV_MODAL'
export const SET_CSV_MODAL_CLOSED = 'SET_CSV_MODAL_CLOSED'
export const CSV_UPLOAD_LOADING = 'CSV_UPLOAD_LOADING'
export const GET_STUDENT_PROGRESS = 'GET_STUDENT_PROGRESS'
export const SET_STUDENT_PROGRESS = 'SET_STUDENT_PROGRESS'

export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const loginUser = createAction(LOG_IN)
export const logoutUser = createAction(LOG_OUT)

export const getStudentProfile = createAction(GET_STUDENT_PROFILE)
export const setStudentProfile = createAction(SET_STUDENT_PROFILE)
export const toggleCSVModal = createAction(TOGGLE_CSV_MODAL)
export const toggleCSVUploadLoading = createAction(CSV_UPLOAD_LOADING)
export const toggleCSVUpload = createAction(CSV_UPLOAD)
export const setCSVModalClosed = createAction(SET_CSV_MODAL_CLOSED)
export const getStudentProgress = createAction(GET_STUDENT_PROGRESS)
export const setStudentProgress = createAction(SET_STUDENT_PROGRESS)