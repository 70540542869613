import { Card } from 'antd';

import './ReportCardStyles.css'
import { useAppSelector } from '../../Redux/Store/hooks';
import React, { useState } from 'react';

const GeneralFeedback = () => {

    const generalFeedback = useAppSelector((state) => state.studentRadarGraphReducer.generalFeedback); 

    const [feedback, setFeedback] = useState(null)

    React.useEffect(() => {
        setFeedback(generalFeedback)
        console.log(generalFeedback)
    },  [generalFeedback])

return (
    <>
       <div>
       <Card title="General Feedback" bordered={false}>
                <p className='feedbackText'>{feedback}</p>
        </Card>
       </div>
    </>
    );
};

export default GeneralFeedback;
