import { Select } from 'antd';
import { useState } from 'react';

const ClassroomFilter = () => {

  const classRooms = [
    {
      value: '8EA',
      label: '8EA',
    },
    {
      value: '8EB',
      label: '8EB',
    },
    {
      value: '8EC',
      label: '8EC',
    },
    {
      value: '8ED',
      label: '8ED',
    }]

  const [selectedClass, updateClassSelection ] = useState("8EA");

  function onSelectionChange(value) {
    if(value!= null){
      console.log(value)
      updateClassSelection(value)
    }
  }

  return(
    <Select
      defaultValue={selectedClass}
      options={classRooms}
      onSelect={onSelectionChange}
      style={{
        width: 120,
        background: 'azure'
      }}

      />
  );

}

export default ClassroomFilter;