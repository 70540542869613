import { Card } from 'antd';

import './ReportCardStyles.css'

const SessionTracker = () => {

    return (
        <>
           <div>
           <Card title="Sessions Completed" bordered={false} >
                <p className='scoreText'>00</p>
            </Card>
           </div>
        </>
        );
    };
    
export default SessionTracker;
    