import { createReducer } from '@reduxjs/toolkit'
import { getStudentProfile, loginUser, logoutUser, setCSVModalClosed, setStudentProfile, setStudentProgress, toggleCSVModal, toggleCSVUploadLoading } from '../Actions/StudentProfileActions'
import { StudentResponseMapper } from '../../Services/DataMappers/StudentResponseMapper'

export const initialState = {
    currentStudent: {
      name: "",
      year: "",
      school: "",
      email: "",
      guardian: ""
    },
    isModalOpen: false,
    isCSVModalOpen: false,
    uploadingCSV: false,
    isLoading: false,
    isLoggedIn: false,
    currentProgress: {
      quizzesCompleted: 0,
      unitsCovered: 0
    }
  }
  
const StudentProfileReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(loginUser, (state) => {
    state.isLoggedIn = true
  })
  .addCase(logoutUser, (state) => {
    state.isLoggedIn = false
  })
  .addCase(getStudentProfile, (state, action) => {
    state.isLoading = true
    state.currentStudent.email = action.payload.email
  })
  .addCase(setStudentProfile, (state, action) => {
    state.currentStudent = action.payload ? StudentResponseMapper(action.payload) : []
    state.isLoading = false
  })
  .addCase(toggleCSVModal, (state) => {
    state.isCSVModalOpen = true
  })
  .addCase(setCSVModalClosed, (state) => {
    state.isCSVModalOpen = false
  })
  .addCase(toggleCSVUploadLoading, (state, action) => {
    if(action.payload){
      state.uploadingCSV = true
    }
    else{
      state.uploadingCSV = false
    }
  })
  .addCase(setStudentProgress, (state, action) => {
    state.currentProgress.quizzesCompleted = action.payload ? action.payload.quizzesCompleted : 0
    state.currentProgress.unitsCovered = action.payload ? action.payload.unitsCovered : 0
  })
 
})

export default StudentProfileReducer;