import { useState, useEffect } from "react";

import mathLib from './math-teacher-library.excalidrawlib';

export default function ExcalidrawComponent() 
{
    const [Excalidraw, setExcalidraw] = useState(null);
    useEffect(() => {
        import("@excalidraw/excalidraw").then((comp) =>
        setExcalidraw(comp.Excalidraw),
        );
    }, []);
    return <>{Excalidraw && 
    <Excalidraw 
    theme={'dark'}
    libraryItems={mathLib}
    />
    
    }
    
    </>;
}