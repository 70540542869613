import React, { useState } from 'react';
import {Table } from 'antd';
import './CSVViewStyles.css'

interface CSVTableProps {
  tableData: any,
  headerKeys : any
}
const { Column } = Table;

const SrudentCSVFileViewTable: React.FC<CSVTableProps> = ({tableData, headerKeys}) => {

  const [data, updateTableData] = useState([])

  React.useEffect(() => {
    
    if(tableData[0].length !== 0){
      updateTableData(tableData[0])
    }
    else{
      updateTableData([])
    }
  }, [tableData]) // eslint-disable-line

 return (
  <div className='csvViewTableContainer'>
  <Table 
    dataSource={data} 
    bordered={true} 
    pagination={false}
    scroll={{ y: 400 }}
    >
    <Column title="Full Name" dataIndex="name" key="name" />
    <Column title="Year" dataIndex="year" key="year" />
    <Column title="Guardian Name" dataIndex="guardian" key="guardian" />
    <Column title="E-Mail" dataIndex="email" key="email" />
    <Column title="School" dataIndex="school" key="school" />
    <Column title="Subject" dataIndex="subject" key="subject" />
  </Table>
  </div>
 )
}


  export default SrudentCSVFileViewTable;
