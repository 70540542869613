export const TypeRadarGraphResponseMapper = (res: any): any => {
    console.log(res);
    const data : any = {
        typeScores: [res.THEORY? calculatePercentage(res.THEORY) : 0, res.ILLUSTRATION? calculatePercentage(res.ILLUSTRATION) : 0, res.WORDED? calculatePercentage(res.WORDED) : 0] // eslint-disable-line
    }

    return data.typeScores;
}

function calculatePercentage(listOfValues : any) : Number {
    console.log(listOfValues);
    return ((listOfValues.correct? listOfValues.correct : 0)/((listOfValues.correct? listOfValues.correct : 0) + (listOfValues.incorrect? listOfValues.incorrect : 0) ))*100; // eslint-disable-line
}