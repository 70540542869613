import { createUrl, post } from "./axiosClient";

export async function getCurrentMonthQuizData(object){
    try {

      const month = parseInt(object.currentMonth, 10); // Parse the year part to an integer
      const year = parseInt(object.currentYear, 10);
      
        const response = await 
            post(createUrl('scores/score/email-date'), {
                  email: object.email,
                  year: year,
                  month: month
           });
        return response;
     } catch (err) {
        console.log(err.message);
        return false
     }
  }; 

  export async function getFilteredMonthQuizData(object){
    try {

      const endDateParts = object.endDate.split('-');
      const startDateParts = object.startDate.split('-');

      // Extracting year and month as integers
      const endYear = parseInt(endDateParts[0], 10); // Parse the year part to an integer
      const endMonth = parseInt(endDateParts[1], 10); // Parse the month part to an integer

      const startYear = parseInt(startDateParts[0], 10); // Parse the year part to an integer
      const startMonth = parseInt(startDateParts[1], 10); 
        const response = await 
            post(createUrl('scores/score/email-date/filter'), {
                  email: object.email,
                  startYear: startYear,
                  endYear: endYear,
                  startMonth:startMonth,
                  endMonth: endMonth
           });
        return response;
     } catch (err) {
        console.log(err.message);
        return false
     }
  }; 