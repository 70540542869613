export const shezanStrengthWiseBreakdown = {
    "data": {
        "totalQuestions": 443,
        "strandWiseCountByLevel": {
            "Algebra": {
                "Sequences and functions": {
                    "Generating sequences (2)": {
                        "EASY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "MEDIUM": {
                            "correct": 3,
                            "incorrect": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Generating sequences (1)": {
                        "EASY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "MEDIUM": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect":3
                        }
                    },
                    "Representing simple functions": {
                        "EASY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "MEDIUM": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "HARD": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    },
                    "Using the nth term": {
                        "EASY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "MEDIUM": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "HARD": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    }
                },
                "Expressions, formulae and Equations": {
                    "Constructing Expressions": {
                        "EASY": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "MEDIUM": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "HARD": {
                            "incorrect": 3,
                            "correct": 2
                        }
                    },
                    "Using expressions and formulae": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Collecting like terms": {
                        "EASY": {
                            "correct": 2,
                            "incorrect": 3
                        },
                        "MEDIUM": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect":4
                        }
                    },
                    "Expanding brackets": {
                        "EASY": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "MEDIUM": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect":4
                        }
                    },
                    "Constructing and solving equations": {
                        "EASY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "MEDIUM": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "HARD": {
                            "correct": 0,
                            "incorrect": 5
                        }
                    }
                },
                "Graphs": {
                    "Graphs": {
                        "Graphs of functions": {
                            "EASY": {
                                "correct": 5,
                                "incorrect": 0
                            },
                            "MEDIUM": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "HARD": {
                                "correct": 2,
                                "incorrect":3
                            }
                        },
                        "Interpreting graphs": {
                            "EASY": {
                                "correct": 5,
                                "incorrect": 0
                            },
                            "MEDIUM": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "HARD": {
                                "correct": 2,
                                "incorrect":3
                            }
                        },
                        "Lines parallel to the axes": {
                            "EASY": {
                                "correct": 5,
                                "incorrect": 0
                            },
                            "MEDIUM": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "HARD": {
                                "correct": 2,
                                "incorrect":3
                            }
                        },
                        "Functions": {
                            "EASY": {
                                "correct": 5,
                                "incorrect": 0
                            },
                            "MEDIUM": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "HARD": {
                                "correct": 2,
                                "incorrect":3
                            }
                        }
                    }
                }

            },
            "Number": {
                "Integers": {
                    "Multiplying and dividing integers": {
                        "EASY": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "MEDIUM": {
                            "incorrect": 1,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    },
                    "Highest common factors": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "HARD": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    },
                    "Adding and Subtracting integers": {
                        "EASY": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "MEDIUM": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "HARD": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    },
                    "Tests for divisibility": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Lowest common multiples": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "HARD": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    }
                },
                "Ratio and Proportion": {
                    "Sharing in a ratio": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Simplifying ratios": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Using direct proportions": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                },
                "Percentages": {
                    "Fractions decimals and percentages": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Percentages large and small": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    }
                },
                "Fractions": {
                    "Making fraction calculations easier": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Adding mixed numbers": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Multiplying fractions": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Ordering fractions": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Dividing fractions": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "HARD": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                },
                "Decimals": {
                    "Dividing decimals": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Multiplying decimals": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Making decimal calculations easier": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Adding and subtracting decimals": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Ordering decimals": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    }
                }
            },
            "Probability": {
                "Probability": {
                    "Mutually exclusive outcomes": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Experimental probabilities": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "The probability scale": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    }
                }
            },
            "Statistics": {
                "Collecting data": {
                    "Conducting an investigation": {
                        "EASY": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "MEDIUM": {
                            "incorrect": 0,
                            "correct": 5
                        },
                        "HARD": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Taking a sample": {
                        "EASY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "MEDIUM": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "HARD": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    }
                }
            }
        },
        "strandWiseCountByType": {
            "Algebra": {
                "Sequences and functions": {
                    "Generating sequences (2)": {
                        "THEORY": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "WORDED": {
                            "correct": 3,
                            "incorrect": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Generating sequences (1)": {
                        "THEORY": {
                            "correct": 2,
                            "incorrect": 2
                        },
                        "WORDED": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "ILLUSTRATION": {
                            "correct": 3
                        }
                    },
                    "Representing simple functions": {
                        "THEORY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 3,
                            "incorrect": 2
                        }
                    },
                    "Using the nth term": {
                        "THEORY": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "WORDED": {
                            "incorrect": 2,
                            "correct": 3
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    }
                },
                "Expressions, formulae and Equations": {
                    "Constructing Expressions": {
                        "THEORY": {
                            "correct": 4,
                            "incorrect": 1
                        },
                        "WORDED": {
                            "correct": 3,
                            "incorrect": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Using expressions and formulae": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Collecting like terms": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Expanding brackets": {
                        "THEORY": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "WORDED": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Constructing and solving equations": {
                        "THEORY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "WORDED": {
                            "incorrect": 5,
                            "correct": 0
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                },
                "Graphs": {
                    "Graphs": {
                        "Graphs of functions": {
                            "THEORY": {
                                "correct": 5,
                                "incorrect": 0
                            },
                            "WORDED": {
                                "incorrect": 1,
                                "correct": 4
                            },
                            "ILLUSTRATION": {
                                "correct": 4,
                                "incorrect": 1
                            }
                        },
                        "Interpreting graphs": {
                            "THEORY": {
                                "incorrect": 3,
                                "correct": 2
                            },
                            "WORDED": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "ILLUSTRATION": {
                                "correct": 1,
                                "incorrect": 4
                            }
                        },
                        "Lines parallel to the axes": {
                            "THEORY": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "WORDED": {
                                "incorrect": 3,
                                "correct": 2
                            },
                            "ILLUSTRATION": {
                                "correct": 2,
                                "incorrect": 3
                            }
                        },
                        "Functions": {
                            "THEORY": {
                                "incorrect": 3,
                                "correct": 2
                            },
                            "WORDED": {
                                "incorrect": 4,
                                "correct": 1
                            },
                            "ILLUSTRATION": {
                                "correct": 1,
                                "incorrect": 4
                            }
                        }
                    }
                }
            },
            "Number": {
                "Integers": {
                    "Multiplying and dividing integers": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Highest common factors": {
                        "THEORY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "WORDED": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Adding and Subtracting integers": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Tests for divisibility": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Lowest common multiples": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                },
                "Ratio and Proportion": {
                    "Sharing in a ratio": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Simplifying ratios": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Using direct proportions": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                },
                "Percentages": {
                    "Fractions decimals and percentages": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Percentages large and small": {
                        "THEORY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "WORDED": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    }
                },
                "Fractions": {
                    "Making fraction calculations easier": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Adding mixed numbers": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Multiplying fractions": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Ordering fractions": {
                        "THEORY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "WORDED": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Dividing fractions": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                },
                "Decimals": {
                    "Dividing decimals": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Multiplying decimals": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Making decimal calculations easier": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "Adding and subtracting decimals": {
                        "THEORY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "WORDED": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Ordering decimals": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                }
            },
            "Probability": {
                "Probability": {
                    "Mutually exclusive outcomes": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    },
                    "Experimental probabilities": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    },
                    "The probability scale": {
                        "THEORY": {
                            "correct": 5,
                            "incorrect": 0
                        },
                        "WORDED": {
                            "incorrect": 1,
                            "correct": 4
                        },
                        "ILLUSTRATION": {
                            "correct": 4,
                            "incorrect": 1
                        }
                    }
                }
            },
            "Statistics": {
                "Collecting data": {
                    "Conducting an investigation": {
                        "THEORY": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "WORDED": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "ILLUSTRATION": {
                            "correct": 2,
                            "incorrect": 3
                        }
                    },
                    "Taking a sample": {
                        "THEORY": {
                            "incorrect": 3,
                            "correct": 2
                        },
                        "WORDED": {
                            "incorrect": 4,
                            "correct": 1
                        },
                        "ILLUSTRATION": {
                            "correct": 1,
                            "incorrect": 4
                        }
                    }
                }
            }
        }
    }
}