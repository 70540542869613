import { combineReducers } from 'redux'
import activityReportReducer from './activityReportReducer'
import StudentProfileReducer from './StudentProfileReducer'
import { StudentLineGraphReducer } from './StudentLineGraphReducer'
import { StudentRadarGraphReducer } from './StudentRadarChartReducer'
import { StudentFeedbackReducer } from './StudentFeedbackReducer'

const rootReducer = combineReducers({
  activityReportReducer: activityReportReducer,
  studentProfileReducer: StudentProfileReducer,
  studentLineGraphReducer: StudentLineGraphReducer,
  studentRadarGraphReducer: StudentRadarGraphReducer,
  studentFeedbacksReducer: StudentFeedbackReducer
})

export default rootReducer