import { createReducer } from '@reduxjs/toolkit'
import { getFeedbacksForUnitAndStrand, setFeedbacksForUnitAndStrand } from '../Actions/StudentFeedbackActions'


export const initialState = {
     currentStrand: null,
     currentUnit: null,
     feedbacks: [],
     isLoading: false
  }


export const StudentFeedbackReducer = createReducer(initialState, (builder) => {
    builder
    .addCase(getFeedbacksForUnitAndStrand, (state, action) => {
      state.currentStrand = action.payload?  action.payload.strand : null
      state.currentUnit = action.payload? action.payload.unit : null
      state.isLoading = true
    })
    .addCase(setFeedbacksForUnitAndStrand, (state, action) => {
      state.isLoading = false
      state.feedbacks = action.payload ? action.payload.feedbacks : []
    })
  })