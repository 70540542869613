import { createUrl, get } from "./axiosClient";

export async function getStudentProfileCall(email){
    try {
        const response = await 
            get(createUrl('students/student/email'), {
               params: {
                  email: email
               }
           });
        return response;
     } catch (err) {
        console.log(err.message);
        return false
     }
  }; 

  export async function getStudentProgressCall(email) {
      try {
         const response = await 
            get(createUrl('responses/summary/progress'), {
               params: {
                  email: email
               }
            });
         return response;
      } catch (err) {
         console.log(err.message);
         return false
      }
  }