export const StudentListZCC = {
  "data": [
    {
      "name": "M.K.M. Naveed",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc19592@pydium.com",
      "school": "TEST"
    },
    {
      "name": "AAQIB FAWAZ",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20085@pydium.com",
      "school": "TEST"
    },
    {
      "name": "N. S. A. Ubaidh",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20092@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M HAMZA HAZMIL",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20098@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. Osman Hussain",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20100@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Z. Ammar",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20110@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M A M HABEEB",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20111@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.M.Yoosuf",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20114@pydium.com",
      "school": "TEST"
    },
    {
      "name": "A. M. Aadhil",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20130@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.M. Umar",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20136@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R.M.YAAMIN",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20141@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R A RAQEEB",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20146@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.I.Thaabith",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20149@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M G ZAIDH",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20153@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.M.M.Zakir",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20163@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. N. M. Zafar",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20164@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M F M QASIM",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20178@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M I MAHDI",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20182@pydium.com",
      "school": "TEST"
    },
    {
      "name": "A.A.AZMER",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20191@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R.I.M.Shadir",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20198@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Yakoob",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20199@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. F. Abdul Malik",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20258@pydium.com",
      "school": "TEST"
    },
    {
      "name": "IBRAHIM MA'MUN",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20296@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Bilal",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20361@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.Zahee",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20368@pydium.com",
      "school": "TEST"
    },
    {
      "name": "N M A UMAIR",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20379@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. S. M. Umar",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20382@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M A M MUFEEZ",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20393@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Abdul Maalik",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20397@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.F.M.Fikry",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20398@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. M. M. Huzayfa",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20426@pydium.com",
      "school": "TEST"
    },
    {
      "name": "R.J.Abdullah",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20432@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.I.M. Raihan",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc20442@pydium.com",
      "school": "TEST"
    },
    {
      "name": "ABOOBAKKAR.M",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc21598@pydium.com",
      "school": "TEST"
    },
    {
      "name": "S.Xavier Mooise",
      "year": "8",
      "className": "8EF",
      "guardian": "zcc",
      "email": "zcc21603@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Zakee Ahamed",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20088@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R. Aymann",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20091@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M .Zaidh Mohamed",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20103@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M F M Aathif",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20105@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Rashdhan Riyas",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20116@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R Luqman",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20120@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Umar Shamroz",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20122@pydium.com",
      "school": "TEST"
    },
    {
      "name": "UMAR ABDULLAH",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20123@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M M Saud AL Shuraim",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20127@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M I M Akhiyar",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20132@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Ammar",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20133@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.F.M.Younus",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20144@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R M SHAHEEM",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20145@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Khubaib",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20150@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M M Shayaan Ahamed",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20161@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Ayman Irfan",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20166@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. Ubaid Shezard",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20170@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Huzman Branudeen",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20174@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Arham Abdul Cader",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20186@pydium.com",
      "school": "TEST"
    },
    {
      "name": "UMAR BIN MISFATH",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20216@pydium.com",
      "school": "TEST"
    },
    {
      "name": "UMAR SAJID",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20220@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. I. Mohamed Isaam",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20282@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.J.M. Ibrahim",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20284@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M H Muhammadh",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20316@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Abdul Muiz Ziyan",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20356@pydium.com",
      "school": "TEST"
    },
    {
      "name": "RAYYAN RIMZAN",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20372@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M A Hanzala",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20396@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Yahya",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20399@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Awwab Mustahq",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20401@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M A M Ahshan",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20404@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M A Abdullah",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20416@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.I.M.Ibraheem",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20427@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. Z. A. Yusuf",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20479@pydium.com",
      "school": "TEST"
    },
    {
      "name": "U. Saajidh",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20480@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M N M Usman",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20508@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohammed Murshid Akram",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc20509@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.M.Ammar",
      "year": "8",
      "className": "8EA",
      "guardian": "zcc",
      "email": "zcc23988@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Amhar",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20081@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed T. Yusuf",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20086@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Muhammed Anfal",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20093@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Althaf",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20097@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.Aahil",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20115@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Husam Aflal",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20118@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Umar",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20125@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohomed Azmath",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20134@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Khidash uhar",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20137@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohammed Yusuf",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20138@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Jaufer Eeman",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20139@pydium.com",
      "school": "TEST"
    },
    {
      "name": "S A USMAN",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20140@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Rayhan",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20142@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.A.Shaheedh",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20143@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. F. M. Aman",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20147@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Hamza Nilufer",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20157@pydium.com",
      "school": "TEST"
    },
    {
      "name": "A.C.M.Muhadh",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20179@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Muhammad Shamail",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20187@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. Rehan Hameed",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20188@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Muhammad Saad",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20189@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. I. M. Shahzaib",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20219@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Muhammad Ashaz",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20287@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohomad Ahzam",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20324@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.Z. Hamdhi",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20359@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Ubaidhul Haleem",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20467@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Ahamed Yoosuf  Haziq",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc20522@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.Razeen Rayyan",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc22115@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. S. M. Usman Marzook",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc23356@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Shaahidh Shuhail",
      "year": "8",
      "className": "8EB",
      "guardian": "zcc",
      "email": "zcc23899@pydium.com",
      "school": "TEST"
    },
    {
      "name": "W Umar",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20094@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Z Hamdhan Yoosuf",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20102@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.I.M. Iz-Haaq",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20107@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.Abdul Kareem",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20112@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M MUAADH ISMATH",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20113@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Ilham Ishfaq Ahamed",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20117@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M H Haiyaan",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20121@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.J.Abdullah",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20128@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Farzan",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20129@pydium.com",
      "school": "TEST"
    },
    {
      "name": "N.Y.M.Sahil",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20151@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R M Yahya",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20167@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M F A Thufail",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20176@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R.M.Husni",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20177@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Humayd Yusri",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20180@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R SHOAIB",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20192@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.M.Amri",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20202@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.Z.M.Zaid",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20203@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Shizan Shakoor",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20207@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Nashwan",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20291@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.AAMIR",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20293@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M I A MAALIK",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20304@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Shahiq",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20305@pydium.com",
      "school": "TEST"
    },
    {
      "name": "T. Rohab B",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20315@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.Umar",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20320@pydium.com",
      "school": "TEST"
    },
    {
      "name": "H.HAMIZ",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20333@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Zaid Zumail",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20339@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.N.UMAR",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20376@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Ubaadha",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20408@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.ABDUL RAHMAN",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20422@pydium.com",
      "school": "TEST"
    },
    {
      "name": "A Nasmi",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20423@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M F R Abdullah",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20441@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Nafeel Mohamed Ihsan",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20484@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M Majid",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20485@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mohamed Saleem",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc20504@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Rameez Hussain",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc21166@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.N.M.Umar",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc23332@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Aamir Fahim",
      "year": "8",
      "className": "8EC",
      "guardian": "zcc",
      "email": "zcc23365@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Ayoob Hussain",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc19875@pydium.com",
      "school": "TEST"
    },
    {
      "name": "AHAMED RAZA",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20079@pydium.com",
      "school": "TEST"
    },
    {
      "name": "S.M.A.Khaalidh",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20080@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.Yasir",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20090@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.M.Zeeshan",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20095@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Umar Irfan",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20106@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. H. D. Ahamed",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20119@pydium.com",
      "school": "TEST"
    },
    {
      "name": "F. Zkariyya",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20159@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M ADEEB RAFIQUE",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20160@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R.R.ABDEEN",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20165@pydium.com",
      "school": "TEST"
    },
    {
      "name": "ABDULLAH M",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20168@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M YOOSUF SALIK",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20175@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R.A.Mahdi",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20184@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Ishaaq Irfan",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20190@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.I.M.Aaqil",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20196@pydium.com",
      "school": "TEST"
    },
    {
      "name": "AAHIL ANEESDEEN",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20197@pydium.com",
      "school": "TEST"
    },
    {
      "name": "ABDUL ALI QAMAR",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20200@pydium.com",
      "school": "TEST"
    },
    {
      "name": "S A AADHIL",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20325@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.NILHAR",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20369@pydium.com",
      "school": "TEST"
    },
    {
      "name": "TUAN MALIK",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20394@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. Nabeel",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20413@pydium.com",
      "school": "TEST"
    },
    {
      "name": "R. A. Rakeeb",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20498@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.R.A.Rihab",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc20499@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Thalha Affan",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc21634@pydium.com",
      "school": "TEST"
    },
    {
      "name": "ZAFEER HAKEEM",
      "year": "8",
      "className": "8ED",
      "guardian": "zcc",
      "email": "zcc23340@pydium.com",
      "school": "TEST"
    },
    {
      "name": "A.S.M.Ammaar",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20082@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.H.W.M. Amhar",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20083@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.M.Atheeb",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20096@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. H. M. Hudaifa",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20101@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.I.RAZIK",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20108@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Sajjad R",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20109@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.M. Shezhard",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20131@pydium.com",
      "school": "TEST"
    },
    {
      "name": "ZAIN MUSTHAQ",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20152@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Usman Shafraz",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20154@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R M RASHDAN",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20155@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.THALHA",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20156@pydium.com",
      "school": "TEST"
    },
    {
      "name": "T.I.J.Hafiz",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20158@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.S.M.Shehran",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20162@pydium.com",
      "school": "TEST"
    },
    {
      "name": "S. M. Shezaad",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20171@pydium.com",
      "school": "TEST"
    },
    {
      "name": "T A AKMAL",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20172@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. A. Muhammed",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20181@pydium.com",
      "school": "TEST"
    },
    {
      "name": "T M H ZEESHAN",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20183@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. L. Ameer",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20185@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M I M YAHYA",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20194@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.M.Asjadh",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20195@pydium.com",
      "school": "TEST"
    },
    {
      "name": "S.R.Amaar",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20234@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.M.Zakariya",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20262@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M R M RASHEED",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20276@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.A.H.M.Arham",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20290@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M N YAHYA",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20306@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Abdullah Ibnuwasim",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20318@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.C.Shaheer Ahamed",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20330@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M B M WALEED",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20338@pydium.com",
      "school": "TEST"
    },
    {
      "name": "A F A AFAF",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20365@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. H. A. Ibrahim",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20367@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M. M. Abdul Hakam",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20395@pydium.com",
      "school": "TEST"
    },
    {
      "name": "IMAAN I",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20405@pydium.com",
      "school": "TEST"
    },
    {
      "name": "M.M.M.Shazeen",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20450@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Rihab Ismail",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc20460@pydium.com",
      "school": "TEST"
    },
    {
      "name": "N.L.N.A. Hakeem",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc23372@pydium.com",
      "school": "TEST"
    },
    {
      "name": "Mahamood Musthaf",
      "year": "8",
      "className": "8EE",
      "guardian": "zcc",
      "email": "zcc23885@pydium.com",
      "school": "TEST"
    }
  ]
}