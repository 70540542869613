import { Col, Row } from 'antd';
import QuizTracker from './QuizTracker';
import DashboardNotification from '../Notification/DashboardNotification';
const DashboardHeader = () => (
  <Row gutter={16}>
    <Col span={12}>
      <DashboardNotification />
    </Col>
    <Col span={12}>
      <QuizTracker />
    </Col>
  </Row>
);
export default DashboardHeader;