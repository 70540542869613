import { MonthlyScore } from "../../Types/MonthlyScoreType";


export const LineGraphResponseMapper = (res: any): any => {
    const scores: MonthlyScore[] = [];
    res.forEach((object: any) => {
        const data : MonthlyScore = {
            monthAndWeek: concat(object.quizYear, object.quizMonth, object.week),
            score: object.finalScore
        }
        scores.push(data)
        
    });
    console.log(scores)
    return scores;
}

function concat(a: string | number, b: string | number, c: string | number) : string {
    return `${a}-${b}-${c}`;
  }