import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';
  import Chart from 'react-apexcharts'
  import './StudentLineGraphStyles.css'
  
  
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  
  const StudentLineGraphApex = (props) => {
  
     const series = [{
      name: 'Score',
      data: props.data
      }]
  
      const options =  {
        chart: {
          type: 'line',
          stackType: '100%',
          width: '80%',
          height: '80%'
        },
        // title: {
        //   text: 'Basic Radar Chart'
        // },
        colors: ['rgba(0, 232, 39, 0.4)'],
                markers: {
                  size: 5,
                  colors: ['rgba(0, 232, 39, 0.5)'],
                  strokeColor: '#007007',
                  strokeWidth: 2,
        },
        xaxis: {
          categories: props.labels
        }
      }
  
  
     
        
  
  return (
    <div className='mainGraph'>
        <Chart options={options} series={series} type="line" />     
    </div>
           
  );
  
  };
  
  export default StudentLineGraphApex;