
export const ConceptFeedbacksMapper = (res: any): any => {
const data = res.map((feedback : any, index: Number) => {
    const { concept, questionsAttempted, conceptScore, expertFeedback } = feedback;
    const conceptScorePercentage = Math.round((conceptScore / questionsAttempted) * 100);
    return {
        key: index.toString(), 
        name: concept,
        questionsAttempted,
        conceptScore: conceptScorePercentage,
        action: expertFeedback
        };
    });
    return data;
}





