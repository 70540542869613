import { Layout } from 'antd';
import ConceptWiseRadarChartTabs from './ConceptGraphs/ConceptWiseRadarChartTabs';
import GeneralFeedback from './GeneralFeedback';
import TutorFeedback from './TutorFeedback';
import QuizTracker from './QuizTracker';
import SessionTracker from './SessionTracker';

const { Header, Footer, Content } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: '#fff'
  };
//   const contentStyle = {
//     textAlign: 'center',
//     minHeight: 120,
//     lineHeight: '120px',
//     color: '#fff',
//     backgroundColor: '#0958d9',
//   };
  // const siderStyle = {
  //   textAlign: 'center',
  //   color: '#fff',
  //   backgroundColor: '#000',
  // };
  const footerStyle = {
    textAlign: 'center',
    color: '#fff',
  };
  const layoutStyle = {
    overflow: 'hidden'
  };

const ReportLayout = () => {

    return (
        <>
        <div className='reportLayout'>
            <Layout style={layoutStyle}>
                <Header style={headerStyle}>
                <div className="trackerRow">
                    <QuizTracker />
                    <SessionTracker />
                </div>
                    <GeneralFeedback />
                    
                </Header>
                <Layout className='conceptWiseTabs'>
                    <Content>
                        <ConceptWiseRadarChartTabs />
                    </Content>
                    {/* <Sider width="25%" style={siderStyle}>
                        <QuizTracker />
                        <SessionTracker />
                    </Sider> */}
                </Layout>
                <Footer style={footerStyle}>
                    <TutorFeedback />
                </Footer>
            </Layout>
            </div>
        </>
        );
    };
    
export default ReportLayout;
    