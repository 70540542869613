import ClassRoomOverview from './ClassRoomOverview';
import QuizAttemptSummary from './QuizAttemptSummary';
import TotalStudentDistribution from './TotalStudentDistribution';
import "./styles.css"
import ClassRoomHeader from './ClassRoomHeader';


const ClassDashboardView = () => {

  return (
    <>
      {<ClassRoomHeader/>}
      {<ClassRoomOverview />}
      <div className="overview-container">
        {<TotalStudentDistribution />}
        {<QuizAttemptSummary />}
      </div>
      </>
  );
}

export default ClassDashboardView;