import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import Strand from './Strand';
import { GET_CONCEPT_SUMMARY } from "../../Redux/Actions/StudentRadarChartActions";

import './styles.css'
import DashboardHeader from './DashboardHeader';

export default function OverView() {

    const dispatch = useAppDispatch();

    const isLoading = useAppSelector((state) => state.studentRadarGraphReducer.isLoading);

    const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email); // eslint-disable-line

    const conceptRadarData = useAppSelector((state) => state.studentRadarGraphReducer.conceptSummary);

    const [radarData, setRadarData] = useState(null) // eslint-disable-line
    const [radarTabs, setRadarTabs] = useState([]) // eslint-disable-line

    useEffect(() => {
        dispatch({
            type: GET_CONCEPT_SUMMARY,
            payload: currentStudentEmail
        })
    }, [])


    useEffect(() => {
        if (conceptRadarData != null) {
            setRadarData(conceptRadarData)
            setRadarTabs(Object.keys(conceptRadarData))
        }
    }, [conceptRadarData]);

    return (
        <div>
            <div className='studentQuizTracker'>
                <DashboardHeader />
            </div>

            <div>
                <Strand
                    isLoading={isLoading}
                    concepts={radarTabs}
                    conceptData={radarData}
                    onChange={(e) => { }} />
            </div>
        </div>
    );
}