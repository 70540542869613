import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const onClick = ({ key }) => {
  console.log(key);
};
const items = [
  {
    label: 'Integers',
    key: '1',
  },
  {
    label: 'Decimals',
    key: '2',
  },
  {
    label: 'Fractions',
    key: '3',
  },
];
const ConceptFilter = () => (
  <Dropdown
    menu={{
      items,
      onClick,
    }}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        Filter by Concept
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
);
export default ConceptFilter;
