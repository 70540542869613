import { createAction } from "@reduxjs/toolkit"

export const GET_CURRENT_MONTH_QUIZ_DATA = 'GET_CURRENT_MONTH_QUIZ_DATA'
export const SET_CURRENT_MONTH_QUIZ_DATA = 'SET_CURRENT_MONTH_QUIZ_DATA'
export const SET_DATE_FILTER = 'SET_DATE_FILTER'
export const GET_FILTERED_QUIZ_DATA = ' GET_FILTERED_QUIZ_DATA'
export const SET_FILTERED_QUIZ_DATA = ' SET_FILTERED_QUIZ_DATA'
export const CLEAR_DATE_FILTER = 'CLEAR_DATE_FILTER'


export const getCurrentMonthQuizData = createAction(GET_CURRENT_MONTH_QUIZ_DATA)
export const setCurrentMonthQuizData = createAction(SET_CURRENT_MONTH_QUIZ_DATA)
export const setDateFilter = createAction(SET_DATE_FILTER)
export const setFilteredQuizData = createAction(SET_FILTERED_QUIZ_DATA)
export const clearDateFilter = createAction(CLEAR_DATE_FILTER)
export const getFilteredQuizData = createAction(GET_FILTERED_QUIZ_DATA)