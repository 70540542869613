import { useState } from 'react';

import { Button, Card } from 'antd';
import './ReportCardStyles.css'


const noFeedbackText = "Need expert feedback? Enroll to our 1-to-1 sessions for specialized guidance and personalized feedback to maximize your potential"
const testFeedback = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui natus facere atque, fugiat odit quia beatae consequuntur doloribus tempora accusamus nesciunt, libero, nobis rerum incidunt magnam reiciendis illo soluta sapiente."
const hasFeedback = false;

const TutorFeedback = () => {

    const [feedback, setFeedback] = useState(noFeedbackText); // eslint-disable-line
 


return (
    <>
       <div className="feedbackContainer">
       <Card title="Tutor Feedback" bordered={false}>
            {hasFeedback ? 
            <p className='feedbackText'>
                {testFeedback}
            </p>
            :
            <>
                <p className='feedbackText'>
                    {noFeedbackText}
                </p>
                <Button type='primary' >Enroll for a free session</Button>
            </>
            }
                
        </Card>
       </div>
    </>
    );
};

export default TutorFeedback;
