export const LevelRadarGraphResponseMapper = (res: any): any => {
    console.log(res);
    const data : any = {
        levelScores: [res.EASY? calculatePercentage(res.EASY) : 0, res.MEDIUM? calculatePercentage(res.MEDIUM) : 0, res.HARD? calculatePercentage(res.HARD) : 0] // eslint-disable-line
    }

    return data.levelScores;
}

function calculatePercentage(listOfValues : any) : Number {
    console.log(listOfValues);
    return ((listOfValues.correct? listOfValues.correct : 0)/((listOfValues.correct? listOfValues.correct : 0) + (listOfValues.incorrect? listOfValues.incorrect : 0) ))*100; // eslint-disable-line
  }