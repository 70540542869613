import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import Chart from 'react-apexcharts'
import './ConceptRadarChartTemplateStyles.css'



ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const ConceptRadarChartTemplateApex = (props) => {

  const series = [{
    name: 'Score',
    data: props.data
  }]

  const options = {
    chart: {
      type: 'radar',
      stackType: '100%'
    },
    // title: {
    //   text: 'Basic Radar Chart'
    // },

    plotOptions: {
      radar: {
        size: 100,
        polygons: {
          strokeColors: 'rgba(99, 1, 148, 0.5)',
          fill: {
            opacity: 0.2,
            colors: ['rgba(99, 1, 148, 0.08)']
          }
        },
      }
    },
    colors: ['rgba(0, 232, 39, 0.4)'],
    markers: {
      size: 5,
      colors: ['rgba(0, 232, 39, 0.5)'],
      strokeColor: '#007007',
      strokeWidth: 2,
    },
    xaxis: {
      categories: props.labels
    },
    yaxis: {
      max: 100
    }
  }

  return (
    <Chart options={options} series={series} type="radar" height='250' />
  );

};

export default ConceptRadarChartTemplateApex;