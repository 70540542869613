import React from 'react';
import { Collapse } from 'antd';
import { GuideConstants } from './GuideConstants';

const questionType = (text) => {
    return (  
    <p
        style={{
        paddingLeft: 24,
        }}
    >
        {text}
    </p>
    );
}

const faqs = (listOfFaqs) => {
    return listOfFaqs.map((faq, index) => {
        return (  
            <div
                key={index}
                style={{
                paddingLeft: 24,
                }}
            >
                <div className='faqQuestion'>{faq.question}</div>
                <div className='faqAnswer'>{faq.answer}</div>
            </div>
            );
    });
   
}


const items = [
  {
    key: '1',
    label: 'Question Type',
    children: questionType(GuideConstants.desciptors.questionType),
  },
  {
    key: '2',
    label: 'Question Level',
    children: questionType(GuideConstants.desciptors.questionLevel),
  },
  {
    key: '3',
    label: 'FAQs',
    children: faqs(GuideConstants.faqs),
  },
];
const HowToGuideSA = () => <Collapse accordion  items={items} bordered={false} defaultActiveKey={['3']} />;
export default HowToGuideSA;