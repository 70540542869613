import { Card } from 'antd';

import './ReportCardStyles.css'

const QuizTracker = () => {

    return (
        <>
           <div>
           <Card title="Quizzes Completed" bordered={true}>
                <p className='scoreText'>04</p>
            </Card>
           </div>
        </>
        );
    };
    
export default QuizTracker;
    