import React from 'react';
import { useAppDispatch } from '../../../Redux/Store/hooks';
import {  Button, Row } from 'antd';
import './CSVActionsStyles.css';
import { TOGGLE_CSV_MODAL } from '../../../Redux/Actions/StudentProfileActions';

// Employee ID, NFC ID, Contract No., Employee Name, Trade 1, Trade 2, Supervisor, Customer No., E-Mail Address, Contact No
// Employee ID, NFC ID, Contract No., Employee Name, Trade 1, Trade 2, Supervisor, Customer No., E-Mail Address, Contact No.
// const headers = [
//   { label: "Employee ID", key: "workerID" },
//   { label: "NFC ID", key: "nfcID" },
//   { label: "Contract No.", key: "contract" },
//   { label: "Employee Name", key: "name" },
//   { label: "Trade 1", key: "" },
//   { label: "Trade 2", key: "" },
//   { label: "Supervisor", key: "" },
//   { label: "Customer No.", key: "" },
//   { label: "E-Mail Address", key: "email" },
//   { label: "Contact No.", key: "" }
// ];

const CSVActions: React.FC = () => {

    const dispatch = useAppDispatch()

    const handleImportCSVButton = () => { // eslint-disable-line
      dispatch({
        type: TOGGLE_CSV_MODAL
      })
    }

 // const currentWorkerList = useAppSelector((state) => state.workerManagementReducer.workers)


  return (
      <>
      <div className='csvActionsContainer'>
        <Row>
            <Button size="large" type='primary' style={{marginRight: "16px"}} onClick={handleImportCSVButton}>Import CSV</Button>
            {/* <CSVLink
                filename={"WorkerList.csv"}
                data={csvExportFileMapper(currentWorkerList)}
                headers={headers}
                enclosingCharacter={''}
                uFEFF={false}
                className="btn btn-primary"
                onClick={()=>{
                  message.success("Downloading Worker Details")
                }}
              >
                <Button size="large" type='primary' style={{width: "100%"}}>Export CSV</Button>
            </CSVLink> */}
        </Row>
    </div>
      </>
    );
  };
  
  export default CSVActions;
