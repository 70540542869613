import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import { Select, Skeleton } from 'antd';
import ConceptRadarChartTemplateApex from "../ConceptRadarCharts/ConceptRadarChartTemplateApex"; // eslint-disable-line

import './styles.css'
import HowToGuideSA from './HowToGuideSA';
import { TypeRadarGraphResponseMapper } from '../../Services/DataMappers/TypeRadarGraphResponseMapper'; // eslint-disable-line
import { LevelRadarGraphResponseMapper } from '../../Services/DataMappers/LevelRadarGraphResponseMapper'; // eslint-disable-line
import { useParams } from 'react-router-dom';
import { GET_CONCEPT_SUMMARY_FOR_STRENGTH } from '../../Redux/Actions/StudentRadarChartActions';
import { filterAndMapByClassName } from '../../Services/DataMappers/FilterAndMapByClassName';
import { StudentListZCC } from '../../MockData/StudentLIstZCC';
import RenderOnRole from '../RouteHelpers/RenderOnRole';


const classRooms = [
    {
        value: '8EA',
        label: '8EA',
    },
    {
        value: '8EB',
        label: '8EB',
    },
    {
        value: '8EC',
        label: '8EC',
    },
    {
        value: '8ED',
        label: '8ED',
    }]


export default function StrengthAnalysis(props) {

    const dispatch = useAppDispatch();

    let { pathStrand, pathUnit } = useParams(); // eslint-disable-line
    const isLoading = useAppSelector((state) => state.studentRadarGraphReducer.isLoading); // eslint-disable-line
    const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email); // eslint-disable-line


    const levelWiseData = useAppSelector((state) => state.studentRadarGraphReducer.strengthAnalysis.strandWiseCountByLevel); // eslint-disable-line
    const typeWiseData = useAppSelector((state) => state.studentRadarGraphReducer.strengthAnalysis.strandWiseCountByType); // eslint-disable-line

    const [subjects, setSubjects] = useState([]) // eslint-disable-line
    const [selectedSubject, setSelectedSubjects] = useState(undefined) // eslint-disable-line
    const [stands, setStrands] = useState([]); // eslint-disable-line
    const [selectedStrand, setSelectedStrand] = useState(); // eslint-disable-line
    const [units, setUnits] = useState([]); // eslint-disable-line
    const [selectedUnit, setSelectedUnit] = useState(); // eslint-disable-line
    const [concepts, setConcepts] = useState([]);// eslint-disable-line
    const [selectedConcept, setSelectedConcept] = useState();// eslint-disable-line

    const [students, setStudents] = useState([]);// eslint-disable-line
    const [selectedStudent, setSelectedStudent] = useState();// eslint-disable-line

    const [classes, setClasses] = useState([]);// eslint-disable-line
    const [selectedClass, setSelectedClass] = useState();// eslint-disable-line


    useEffect(() => {
        setClasses(classRooms)
        dispatch({
            type: GET_CONCEPT_SUMMARY_FOR_STRENGTH,
            payload: currentStudentEmail
        });
    }, []);


    useEffect(() => {
        dispatch({
            type: GET_CONCEPT_SUMMARY_FOR_STRENGTH,
            payload: selectedStudent
        });
    }, [selectedStudent]);


    const handleConceptsAndUnitsChange = (value, type) => {
        if(type === 'strand') {
            setSelectedStrand(value)
            const units = Object.keys(levelWiseData[value]);
            setUnits(units);
            setSelectedUnit(units ? units[0] : undefined);
            const concepts = Object.keys(levelWiseData[value][units[0]]);
            setConcepts(concepts);
            setSelectedConcept(concepts ? concepts[0] : undefined);

        }
        if (type === 'unit') {
            setSelectedUnit(value);
            const concepts = Object.keys(levelWiseData[selectedStrand][value]);
            setConcepts(concepts);
            setSelectedConcept(concepts ? concepts[0] : undefined);
        }

        if (type === 'concept') {
            setSelectedConcept(value);
        }
    }

    const handleYearChange = (value) => {
        setStudents(filterAndMapByClassName(StudentListZCC.data, value));
        setSelectedClass(value);
        console.log(value)
    }

    const handleStudentChange = (value) => {
        setSelectedStudent(value);
        console.log(value)
    }



    useEffect(() => {
        if(levelWiseData != null) {
        if(pathStrand && pathUnit) {
            console.log(pathStrand)
            console.log(pathUnit)
            const strands = Object.keys(levelWiseData);
            const unitNames = Object.keys(levelWiseData[pathStrand]);
            const concepts = Object.keys(levelWiseData[pathStrand][pathUnit]);
            console.log(levelWiseData[pathStrand][pathUnit])
            setStrands(strands);
            setSelectedStrand(pathStrand);
            setUnits(unitNames);
            setSelectedUnit(pathUnit);
            setConcepts(concepts);
            setSelectedConcept(concepts[0]);
            console.log(concepts[0])
        }
        else {
            const strands = Object.keys(levelWiseData);
            const unitNames = Object.keys(levelWiseData[strands[0]]);
            const concepts = Object.keys(levelWiseData[strands[0]][unitNames[0]]);
            console.log(levelWiseData[strands[0]][unitNames[0]][concepts[0]])
            setStrands(strands);
            setSelectedStrand(strands[0]);
            setUnits(unitNames);
            setSelectedUnit(unitNames[0]);
            setConcepts(concepts);
            setSelectedConcept(concepts[0]);
        }
    }
    }, [levelWiseData]);


    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'end',
                columnGap: '2em'
            }}>
                <RenderOnRole roles={['admin', 'school']}>
                <div className="filter-comp">
                    <label>Class</label>
                    <Select
                      id="swat-strand"
                      size={'large'}
                      value={selectedClass}
                      onChange={(v) => handleYearChange(v)}
                      style={{
                          width: 200
                      }}
                      options={classes}
                    />
                </div>
                <div className="filter-comp">
                    <label>Student</label>
                    <Select
                      id="swat-strand"
                      size={'large'}
                      value={selectedStudent}
                      onChange={(v) => handleStudentChange(v)}
                      style={{
                          width: 200
                      }}
                      options={students}
                    />
                </div>
                </RenderOnRole>
                <div className="filter-comp">
                    <label>Strand</label>
                    <Select
                      id="swat-strand"
                      size={'large'}
                      value={selectedStrand}
                      onChange={(v) => handleConceptsAndUnitsChange(v, 'strand')}
                      style={{
                          width: 200
                      }}
                      options={stands.map((item) => {
                          return {
                              label: item,
                              value: item
                          };
                      })}
                    />
                </div>
                <div className="filter-comp">
                    <label>Unit</label>
                    <Select
                      id="swat-unit"
                      size={'large'}
                      value={selectedUnit}
                      onChange={(v) => handleConceptsAndUnitsChange(v, 'unit')}
                      style={{
                          width: 200
                      }}
                      options={units.map((item) => {
                          return {
                              label: item,
                              value: item
                          };
                      })}
                    />
                </div>
                <div className="filter-comp">
                    <label>Concept</label>
                    <Select
                      id="swat-concept"
                      size={'large'}
                      value={selectedConcept}
                      onChange={(v) => handleConceptsAndUnitsChange(v, 'concept')}
                      style={{
                          width: 200
                      }}
                      options={concepts.map((item) => {
                          return {
                              label: item,
                              value: item
                          };
                      })}
                    />
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: '2em',
                columnGap: '2em'
            }}>
                {isLoading ? <Skeleton active /> :
                  <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                  }}>
                      {levelWiseData && selectedStrand && selectedUnit && selectedConcept ?
                        <>
                            <div className="selectedConcept">{selectedConcept}</div>
                            <div className='radarchartContainerSTA'>
                        <div className='radarChartTitle'>Question Type</div>
                        <ConceptRadarChartTemplateApex labels={['Theory', 'Composite', 'Worded']} data={TypeRadarGraphResponseMapper(typeWiseData[selectedStrand][selectedUnit][selectedConcept])} />
                    </div>
                    <div className='radarchartContainerSTA'>
                        <div className='radarChartTitle'>Question Level</div>
                        <ConceptRadarChartTemplateApex  labels={['Easy', 'Medium', 'Hard']} data={LevelRadarGraphResponseMapper(levelWiseData[selectedStrand][selectedUnit][selectedConcept])} />
                    </div>
                    </>
                         : <></>}
                </div>
                }
                <div className='howToGuideContainer'>
                   <HowToGuideSA />
                </div>
            </div>
        </div>
    );
}