import { createAction } from "@reduxjs/toolkit"

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const TOGGLE_CLEAR_NOTIFICATION = 'TOGGLE_CLEAR_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const SET_SUCCESS_NOTIFICATION = 'SET_SUCCESS_NOTIFICATION'
export const SET_ERROR_NOTIFICATION = 'SET_ERROR_NOTIFICATION'
export const SET_LIVE_NOTIFICATION = 'SET_LIVE_NOTIFICATION'
export const SET_GENERIC_NOTIFICATION = 'SET_GENERIC_NOTIFICATION'


export const setNotification = createAction(SET_NOTIFICATION)
export const toggleClearNotification = createAction(TOGGLE_CLEAR_NOTIFICATION)
export const clearNotification = createAction(CLEAR_NOTIFICATION)
export const setSuccessNotification = createAction(SET_SUCCESS_NOTIFICATION)
export const setErrorNotification = createAction(SET_ERROR_NOTIFICATION)
export const setLiveNotification = createAction(SET_LIVE_NOTIFICATION)
export const setGenericNotification = createAction(SET_GENERIC_NOTIFICATION)