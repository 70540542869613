import Chart from 'react-apexcharts';

const QuizAttemptSummary = () => {
  //
  // const series = [{
  //   data: [{
  //     x: 'Quiz 10',
  //     y: 10
  //   }, {
  //     x: 'Quiz 11',
  //     y: 18
  //   }, {
  //     x: 'Quiz 12',
  //     y: 13
  //   }]
  // }]

  const chartOptions = {
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: ['Quiz 10', 'Quiz 11', 'Quiz 12']
      }
    },
    series: [
      {
        name: "# of Students",
        data: [30, 40, 45]
      }
    ]
  };


  // const postiveImpactColor = "green";
  // const negativeImpactColor= "red";

  return(
    <div className="quiz-attempt-container">
      <div>
        <p>Quiz Attempt Overview</p>
      </div>
      <div className="studentQuizAttemptChartContainer">
        <Chart options={chartOptions.options} series={chartOptions.series} type="bar" width={500}/>
      </div>
    </div>

  );

}

export default QuizAttemptSummary;