import React, { useState } from 'react';
import { useAppSelector } from "../../Redux/Store/hooks";
import { Line } from '@ant-design/charts'; // eslint-disable-line

import { Skeleton } from 'antd';
import './StudentLineGraphStyles.css'
import StudentLineGraphApex from './StudentLineGrappApex';



const StudentLineGraph = () => {

  const isLoading = useAppSelector((state) => state.studentLineGraphReducer.isLoading);
  const currentGraphData = useAppSelector((state) => state.studentLineGraphReducer.curretGraphData); // eslint-disable-line

  const [data, setData] = useState([])

  React.useEffect(() => {
    console.log(currentGraphData)
    setData(currentGraphData)
  }, [currentGraphData])

  const config = { // eslint-disable-line

    data,
    xField: 'monthAndWeek',
    yField: 'score',
    point: {
      shapeField: 'square',
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    label: {
      style: {
        fill: 'red'
      }
    },
    axis: {
      style: {
        stroke: 'red',
        fail: 'red'
      }
    },
    style: {
      lineWidth: 2,
    },
 };

 const extractLabelsFromObject = (object) => {
  const labels = [];
  object.forEach((item) => {
    labels.push(item.monthAndWeek);
  })
  console.log(labels);
  return labels;
}

const extractValuesFromObject = (object) => {
  const values = [];
  object.forEach((item) => {
    values.push(item.score);
  })
  console.log(values);
  return values;
}

  return (
      <>
        {isLoading? <Skeleton active /> : <StudentLineGraphApex labels={extractLabelsFromObject(data)} data={extractValuesFromObject(data)} />}
      </>
    );

   
};
export default StudentLineGraph;