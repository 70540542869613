import { Button } from "antd";
import Typewriter from 'typewriter-effect';

import './LandingPageStyles.css'



const LandingPage = () => {

    return (
      <div className="landing-page">
        <div className="content-container">
          <p className="welcomeTitle">The only platform you need to track your kids education the easy way</p>
          <Typewriter
            options={{
              strings: ['Online Quizzes', '1-to-1 Coaching', 'Expert Feedback'],
              autoStart: true,
              loop: true,
              deleteSpeed: 30,
              delay: 30
            }}
          />
          <Button type="primary" className="registerButton">Register Now</Button>
          <Button className="watchVideoButton">Watch Video</Button>
        </div>
      </div>
        );
    };
    
export default LandingPage;
    