import Chart from 'react-apexcharts';

const TotalStudentDistribution = () => {

  const values = [10, 15, 20];
  const labels = ["Above level", "At level", "Below level"];

  const chartOptions = {
    labels: labels
  }

  //
  // const totalStudents = 21;
  // const percentageChange = 2.5;

  // const postiveImpactColor = "green";
  // const negativeImpactColor= "red";

  return(
    <div className="total-student-count-container">
      <div className="total-student-count-view">
        <p>Student Level Overview</p>
      </div>
      <div className="studentCountDonutContainer">
        <Chart series={values} options={chartOptions} type={"donut"} width={500}/>
      </div>
    </div>

  );

}

export default TotalStudentDistribution;