import { FileDoneOutlined, ProfileOutlined, TeamOutlined } from '@ant-design/icons';


const ClassRoomOverview = () => {

  const studentCount = 45;
  const quizzesAssignedCount = 10;
  const unitsCoveredCount = 4;

  // eslint-disable-next-line no-unused-vars
  const overviewBlock = (icon, value, title) => {
    return (
      <div className="classroom-overview-container">
        {icon}
        <div className="classroom-overview-value">{value}</div>
        <div className="classroom-overview-title">{title}</div>
      </div>
    )
  }


  return (
    <div className="overview-block">
      {overviewBlock(<TeamOutlined  style={{color: "#4E89FF"}}/>, studentCount, 'Students')}
      {overviewBlock(<ProfileOutlined style={{color: "#ff0099"}}/>, quizzesAssignedCount, 'Quizzes')}
      {overviewBlock(<FileDoneOutlined style={{color: "#ba06b4"}}/>, unitsCoveredCount, 'Units Covered')}
    </div>
  );
}

export default ClassRoomOverview;