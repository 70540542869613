import { takeLatest, put, call } from "redux-saga/effects";
import { setErrorNotification } from "../Actions/notificationManagerActions";
import { GET_CONCEPT_SUMMARY, GET_CONCEPT_SUMMARY_FOR_STRENGTH, GET_CURRENT_FEEDBACK, GET_CURRENT_SUMMARY, clearFeedback, setConceptSummary, setConceptSummaryForStrength, setCurrentFeedback, setCurrentSummary } from "../Actions/StudentRadarChartActions";
import { getConceptStrength, getConceptSummary, getFeedbacksSummary, getStudentSummary } from "../../Services/API/StudentRadarGraphAPI";  // eslint-disable-line
import { shezanConceptBreakdown } from "../../MockData/ConceptBreakdown_Shezan";
import { shezanStrengthWiseBreakdown } from "../../MockData/ConceptWiseStrengthBreakdown_Shezan";
// import { ConceptBreakdown } from "../../MockData/ConceptBreakdown";
// import { levelWiseData } from "../../MockData/LevelWiseBreakdown";
// import { conceptWiseStrengthBreakdown } from "../../MockData/ConceptWiseStrengthBreakdown";

function* getCurrentSummarySaga(action) {
    if(action.payload === "riz@gmail.com" || action.payload === "ahmad@pydium.com") {
        yield put(setCurrentSummary(shezanConceptBreakdown.data))
    }
    else{
    try {
        console.log(action.payload)
        const response = yield call(getStudentSummary, action.payload)

        if (response.status === 200) {
            yield put(setCurrentSummary(response.data.data))
        }
        else {
            yield put(setErrorNotification("Failed to fetch student summary data"))
        }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
    }
    // yield put(setCurrentSummary(levelWiseData.data))
}

function* getConceptSummarySaga(action) {
    if(action.payload === "riz@gmail.com" || action.payload === "ahmad@pydium.com") {
        yield put(setConceptSummary(shezanConceptBreakdown.data))
    }
    else{
    try {
        console.log(action.payload)

        const response = yield call(getConceptSummary, action.payload)

        if (response.status === 200) {
            yield put(setConceptSummary(response.data.data))
        }
        else {
            yield put(setErrorNotification("Failed to fetch concept summary data"))
        }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
}
    // yield put(setConceptSummary(ConceptBreakdown.data))
}

function* getConceptSummaryForStrengthSaga(action) {
    if(action.payload === "riz@gmail.com" || action.payload === "ahmad@pydium.com") {
        yield put(setConceptSummaryForStrength(shezanStrengthWiseBreakdown.data))
    }
    else{
    try {
        console.log(action.payload)

        const response = yield call(getConceptStrength, action.payload)

        if (response.status === 200) {
            yield put(setConceptSummaryForStrength(response.data.data))
        }
        else {
            yield put(setErrorNotification("Failed to fetch concept summary data"))
        }
        
    } catch (error) {
        console.log((error))
        yield put(setErrorNotification("Network Error"))
    }
}
    // yield put(setConceptSummaryForStrength(conceptWiseStrengthBreakdown.data))
}

function* getCurrentFeedbackSaga(action) {
    try {
        console.log(action.payload)
        const feedbacks = yield call(getFeedbacksSummary, action.payload)

        if (feedbacks.status === 200) {
            yield put(setCurrentFeedback(feedbacks.data.data))
        }
        else {
            yield put(clearFeedback())
            yield put(setErrorNotification("Failed to fetch feedbacks"))
        }

    } catch (error) {
        console.log((error))
        yield put(clearFeedback())
        yield put(setErrorNotification("Network Error"))
    }
}


export default function* watchStudentRadarGraphSaga() {
    yield takeLatest(GET_CURRENT_FEEDBACK, getCurrentFeedbackSaga)
    yield takeLatest(GET_CONCEPT_SUMMARY_FOR_STRENGTH, getConceptSummaryForStrengthSaga)
    yield takeLatest(GET_CURRENT_SUMMARY, getCurrentSummarySaga)
    yield takeLatest(GET_CONCEPT_SUMMARY, getConceptSummarySaga)

}