import PropTypes from 'prop-types'
import UserService from "../../Services/UserService";
import NotAllowed from "./NotAllowed";

const RolesRoute = ({ roles, children}) => (
    UserService.hasRole(roles) ? children : <NotAllowed/>
)

RolesRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RolesRoute
