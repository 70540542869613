import { createReducer } from '@reduxjs/toolkit'
import { clearFeedback, getConceptSummary, getCurrentSummary, setConceptSummary, setConceptSummaryForStrength, setCurrentFeedback, setCurrentSummary } from '../Actions/StudentRadarChartActions'
import { ConceptRadarGraphsResponseMapper } from '../../Services/DataMappers/ConceptRadarGraphsResponseMapper'


export const initialState = {
    strengthAnalysis: {
      strandWiseCountByLevel: null,
      strandWiseCountByType: null
    },
    generalFeedback: "N/A",
    conceptSummary: null,
    isLoading: false
  }


export const StudentRadarGraphReducer = createReducer(initialState, (builder) => {
    builder
    .addCase(getCurrentSummary, (state) => {
      state.isLoading = true
    })
    .addCase(getConceptSummary, (state) => {
      state.isLoading = true
    })
    .addCase(setCurrentSummary, (state, action) => {
      state.strengthAnalysis = action.payload ? action.payload : []
      state.isLoading = false
    })
    .addCase(setCurrentFeedback, (state, action) => {
      console.log(action.payload)
      state.generalFeedback = action.payload ? action.payload.generalFeedback : "N/A"
      state.isLoading = false

    })
    .addCase(setConceptSummary, (state, action) => {
      state.conceptSummary = action.payload ? ConceptRadarGraphsResponseMapper(action.payload) : null
      state.isLoading = false
    })
    .addCase(clearFeedback, (state) => {
      state.generalFeedback = "N/A"
    })
    .addCase(setConceptSummaryForStrength, (state, action) => {
      state.strengthAnalysis.strandWiseCountByLevel = action.payload ? action.payload.strandWiseCountByLevel : null
      state.strengthAnalysis.strandWiseCountByType = action.payload ? action.payload.strandWiseCountByType : null
      console.log(action.payload.strandWiseCountByType)
      state.isLoading = false

    })
  })