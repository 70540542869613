import { shezanConceptBreakdown } from "../../MockData/ConceptBreakdown_Shezan";

export const ConceptRadarGraphsResponseMapper = (res: any): any => {
    const mainKeys: string[] = Object.keys(res.strandUnitConceptBreakdown);
    const units: any = {};
    const updatedUSCTemp: any = JSON.parse(JSON.stringify(shezanConceptBreakdown.data.strandUnitConceptBreakdown)); // Create a deep copy of USCTemplate
    mainKeys.forEach((unit: string) => {
        units[unit] = {};
        const strands: string[] = Object.keys(res.strandUnitConceptBreakdown[unit]);
        strands.forEach((category: string) => {
            units[unit][category] = {};
            const subCategories: string[] = Object.keys(res.strandUnitConceptBreakdown[unit][category]);
            subCategories.forEach((subCategory: string) => {
                updatedUSCTemp[unit][category][subCategory] = {
                    value: calculatePercentage([res.strandUnitConceptBreakdown[unit][category][subCategory].numberOfCorrect, res.strandUnitConceptBreakdown[unit][category][subCategory].numberOfIncorrect])
                };
            });
        });

    })

    
    console.log(updatedUSCTemp)
    return updatedUSCTemp;
}

function calculatePercentage(listOfValues : any) : Number {
    return (listOfValues[0]/(listOfValues[0] + listOfValues[1]))*100; // eslint-disable-line
  }





