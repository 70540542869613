import { useEffect, useState } from 'react';
import { Collapse, Skeleton, Tabs, Button, Card, Grid, Row, Col, Flex, Badge } from 'antd'; // eslint-disable-line
import './styles.css'
import { useNavigate } from 'react-router-dom';


export default function Strand(props) {

    const [selectedConcept, setSelectedConcept] = useState('');
    const navigate = useNavigate();

    const onUnitClick = (e) => {
        navigate("/strength-analysis/" + selectedConcept +'/' + e.target.innerText);
    }

    const conceptCategories = (conceptName) => {
        const categories = Object.keys(props.conceptData[conceptName])
        return (
        <>
            <div className='overviewTitle'>Units</div>
            <Flex wrap='wrap' gap={'2em'}>
                {(categories || []).map((category, index) => {
                    return (
                        <Badge.Ribbon key={`concept-${index}`} text="80%" color='green'>
                        <Card hoverable
                            key={`concept-${index}`}
                            className='category'
                            onClick={onUnitClick}
                        >
                            {category}
                        </Card>
                        </Badge.Ribbon>
                    )
                })}
            </Flex>
            </>
        );
    }

    useEffect(() => {
        if (props.concepts && props.concepts.length > 0) {
            setSelectedConcept(props.concepts[0]);
        }
    }, [props.concepts]);

    return (
        <div className='strand-root'>
            {props.isLoading ? < Skeleton active /> :
                <>
                    <Tabs
                        tabPosition='left'
                        className=''
                        defaultActiveKey={selectedConcept}
                        centered
                        items={props.concepts.map((item) => {
                            return {
                                label: item,
                                key: item,
                                children: conceptCategories(item),
                            }
                        })}
                        onChange={(key) => {
                            setSelectedConcept(key);
                            props.onChange(key);
                        }}
                    />
                </>
            }
        </div>);
}