import { useKeycloak } from "@react-keycloak/web";
import UserService from "../../Services/UserService"; // eslint-disable-line
import AdminOnlyComponent from "../AdminComponents/AdminOnlyComponent";
import RenderOnRole from "../RouteHelpers/RenderOnRole";


import StudentProfileInfo from "../StudentProfileInfo/StudentProfileInfo";
import StudentDashboardTabs from "../StudentDashboardTabs/StudentDashboardTabs";

import './WelcomePageStyles.css'
import LandingPage from "../LandingPage/LandingPage";

const Welcome = () => {

  const { keycloak } = useKeycloak();


 return (
  <>
     {!keycloak.authenticated && (
      <>
        <LandingPage />
      </>
                   
                 )}
      {!!keycloak.authenticated && (
                  <>
                   {/* <h1>Welcome {UserService.getUsername()}</h1>  */}
                   <RenderOnRole roles={['admin']}>
                      <AdminOnlyComponent />
                   </RenderOnRole>
                   <StudentProfileInfo />
                   <StudentDashboardTabs />
                   </>
                 )}
      
  </>
 );
};

export default Welcome;
